import type { AnyAction } from 'redux';

const initialState = {};

export default function(state = initialState, action: AnyAction) {
    switch (action.type) {
        default:
            return state;
    }
}
