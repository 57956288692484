import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import { UiErrorBoundary } from '@experiences/ui-common';
import { ApToastManagerInternal } from '@uipath/portal-shell-react';
import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';

import { Router } from './Router';
import store from './store';

export const App = () => (
    <StrictMode>
        <UiErrorBoundary>
            <Provider store={store}>
                <IntlProvider>
                    <ApolloThemeProvider>
                        <SWRConfig value={{ revalidateOnFocus: false }}>
                            <Router />
                            <ApToastManagerInternal />
                        </SWRConfig>
                    </ApolloThemeProvider>
                </IntlProvider>
            </Provider>
        </UiErrorBoundary>
    </StrictMode>
);
