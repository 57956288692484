import type { IUiLinkMap } from '@experiences/interfaces';

export const GenericErrorMessages: IUiLinkMap = new Map([
    [ 'en', `Oops! Something went wrong on our end. We are working to get it fixed as soon as we can. Please give it a try later or contact UiPath Support for help.` ],
    [ 'ja', `問題が発生しました。現在、迅速な復旧に向けて対処しております。後でもう一度やり直すか、UiPath サポートにお問い合わせください。` ],
    [ 'de', `Leider ist auf unserer Seite ein Fehler aufgetreten. Wir arbeiten daran, das Problem so schnell wie möglich zu beheben. Bitte versuchen Sie es später erneut, oder wenden Sie sich an den UiPath-Support, um Hilfe zu erhalten.` ],
    [ 'fr', `Oups! Une erreur s’est produite de notre côté. Nous nous efforçons de résoudre le problème au plus vite. Réessayez plus tard ou contactez l’assistance d’UiPath pour obtenir de l’aide.` ],
    [ 'es', `Algo ha ido mal. Estamos trabajando para arreglarlo tan pronto como podamos. Vuelva a intentarlo más tarde o póngase en contacto con el soporte técnico de UiPath para obtener ayuda.` ],
    [ 'pt-BR', `Opa! Algo deu errado no nosso sistema. Estamos trabalhando para corrigir o erro o quanto antes. Tente novamente mais tarde ou entre em contato com o Suporte da UiPath para obter assistência.` ],
    [ 'es-MX', `Algo ha salido mal. Estamos trabajando para arreglarlo tan pronto como podamos. Vuelva a intentarlo más tarde o póngase en contacto con el soporte técnico de UiPath para obtener ayuda.` ],
    [ 'ko', `이런! UiPath 시스템에서 문제가 발생했습니다. 가급적 빨리 문제를 해결하기 위해 노력하고 있습니다. 나중에 다시 시도하거나 UiPath 지원팀에 도움을 요청하세요.` ],
    [ 'zh-CN', `糟糕! 我们这边出现了问题。我们正在尽快修复错误，请稍后重试或联系 UiPath 支持团队寻求帮助。` ],
    [ 'zh-TW', `糟糕! 我們這邊發生問題。我們正在儘快修正錯誤。請稍後重試或聯繫 UiPath 支援團隊尋求協助。` ],
    [ 'pt', `Ups! Ocorreu um erro do nosso lado. Estamos a trabalhar para o corrigir o mais depressa possível. Tente mais tarde ou contacte o Suporte da UiPath para obter ajuda.` ],
    [ 'tr', `Hata! Sistemimizde bir şeyler ters gitti. Mümkün olan en kısa sürede düzeltmek için çalışıyoruz. Lütfen daha sonra deneyin veya yardım için UiPath Desteği ile iletişime geçin.` ],
    [ 'ru', `У нас что-то пошло не так. Мы работаем над исправлением в кратчайшие сроки. Пожалуйста, повторите попытку позже или обратитесь за помощью в службу поддержки UiPath.` ],
]);
