import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import { UiText } from '../UiText/UiText';

const useStyles = makeStyles(() =>
    createStyles({
        dividerContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        divider: {
            width: '40%',
            background: '#ddd',
        },
        centerText: { width: '20%' },
        or: {
            display: 'flex',
            justifyContent: 'space-around',
        },
    }),
);

export const UiDividerWithText: React.FC<{
    text: string;
}> = ({ text }) => {
    const classes = useStyles();

    return (
        <div className={classes.dividerContainer}>
            <Divider className={classes.divider} />
            <div className={classes.centerText}>
                <UiText className={classes.or}>
                    {text}
                </UiText>
            </div>
            <Divider className={classes.divider} />
        </div>
    );
};
