import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRouteResolver } from './useRouteResolver';

export function useProtectRouteFromMissingParams(params: any[], rerouteName: string) {
    // eslint-disable-next-line ban/ban
    const navigate = useNavigate();
    const getRoute = useRouteResolver();

    useEffect(() => {
        if (params.some(param => !!param)) {
            navigate(getRoute(rerouteName));
        }
    }, [ getRoute, params, rerouteName, navigate ]);
}
