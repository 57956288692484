import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            minHeight: '100vh',
        },
        rightContainer: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        mainContainer: {
            display: 'flex',
            width: '100%',
            height: 'fit-content',
        },
        leftContainer: {},
        '@media all and (max-width: 1100px)': { leftContainer: { display: 'none' } },
    }),
);

interface IFlexRatio {
    right: number;
    left: number;
}

interface ISignupChildrenProps {
    left: JSX.Element;
    right: JSX.Element;
    flexRatio?: IFlexRatio;
    showLeftContainer?: boolean;
    bottomOptions?: JSX.Element;
}

export const UiSignupLayout = ({
    left, right, flexRatio, showLeftContainer = true, bottomOptions,
}: ISignupChildrenProps) => {
    const classes = useStyles();

    return (
        <div
            className={classes.mainContainer}
            data-cy="main-signup-layout">
            {
                showLeftContainer &&
                <div
                    className={clsx(classes.container, classes.leftContainer)}
                    style={{ flex: flexRatio?.left ?? 1 }}>
                    {left}
                </div>
            }
            <div
                className={clsx(classes.container, classes.rightContainer)}
                style={{ flex: flexRatio?.right ?? 1 }}>
                <div style={{ minHeight: '15vh' }} />
                {right}
                {bottomOptions}
            </div>
        </div>
    );
};
