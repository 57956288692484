export enum ConsumablesEvent {
    /**
         * This is triggered when an admin goes to admin -> Licenses -> and clicks on Consumables tab
         */
    NavigateTab = 'Units.NavigateTab',
    /**
        * This is triggered when a user clicks on the "View usage" button on the RU card in order to see the consumption graphs
        */
    RuViewUsage = 'RobotUnits.ViewUsage',
    /**
        * This is triggered when a user clicks on the "View usage" button on the AIU card in order to see the consumption graphs
        */
    AiuViewUsage = 'AiUnits.ViewUsage',
    /**
        * This is triggered when a user clicks on the "View usage" button on the API calls card in order to see the consumption graphs
        */
    ApiCallsViewUsage = 'APICalls.ViewUsage',
}
