export enum TrialServiceEvent {
    /**
     * This is triggered when an admin goes to Admin → Licenses → and clicks on Trial Services.
     */
    NavigateTab = 'TrialService.NavigateTab',
    /**
     * This is triggered when someone requests a trial for a service, but doesn’t click on confirmation pop-up → available only for Cloud.
     */
    StartTrial = 'TrialService.StartTrial',
    /**
     * Triggered when they click on confirmation - this is when the trial license is allocated in Boon.
     */
    Confirmed = 'TrialService.Confirmed',
    /**
     * Triggered when the user clicks on Contact Sales button after they requested the trial
     * (either the trial is in valid period or it’s expired).
     */
    ContactSales = 'TrialService.ContactSales',
}
