import {
    Features,
    getFeatureFlagValue,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { defaultLanguage } from '@uipath/portal-shell-util';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    createIntl,
    createIntlCache,
    RawIntlProvider,
} from 'react-intl';
import { useLocation } from 'react-router-dom';

import type { LanguageCulture } from './language';
import {
    getUserLanguageFromLocalStorage,
    isValidLanguageCode,
    processLocaleKeys,
    setUserLanguageInLocalStorage,
} from './LocaleAvailability';
import en from './translation-en.json';

interface IIntlContext {
    locale: string;
    setLocale: (_: LanguageCulture) => void;
    setRoute: (_: string) => void;
}

export const IntlWrapperContext = React.createContext<IIntlContext>({
    locale: 'en',
    setLocale: () => {},
    setRoute: () => {},
});

const cache = createIntlCache();

export function useUpdateLanguage() {
    const { setLocale } = useContext(IntlWrapperContext);
    const isGov = window.env?.ENVIRONMENT?.includes('gov');

    const updateLanguage = useCallback((language: string) => {
        if (isGov) {
            return;
        }

        const languageCode = isValidLanguageCode(language);
        if (languageCode) {
            setUserLanguageInLocalStorage(languageCode);
            setLocale(languageCode);
        }
    }, [ setLocale, isGov ]);

    useEffect(() => {
        const handleLanguageChange = (event: any) => {
            if (isGov) {
                return;
            }
            updateLanguage(event.detail.selectedLanguageId);
        };
        document.addEventListener('languageChanged', handleLanguageChange);
        return () => document.removeEventListener('languageChanged', handleLanguageChange);
    }, [ updateLanguage, isGov ]);

    return updateLanguage;
}

export const useUpdateLocalizationRoute = () => {
    const { setRoute } = useContext(IntlWrapperContext);
    const location = useLocation();

    const EnableHomePageLocaleSplit = useFeatureFlagValue(Features.EnableHomePageLocaleSplit.name);

    useEffect(() => {
        const pathname = location.pathname;
        const baseRoute = process.buildConfigs.tabNameRoute + '/';
        const routeString = pathname.substring(pathname.indexOf(baseRoute) + baseRoute.length);
        const path = routeString.substring(0, routeString.indexOf('/')) || routeString.substring(0);

        if (!EnableHomePageLocaleSplit || path !== 'home') {
            setRoute('');
            return;
        }

        setRoute(path);
    }, [ location.pathname, setRoute, EnableHomePageLocaleSplit ]);
};

export const useLocalization = (useStrict = true) => {
    const { locale } = useContext(IntlWrapperContext);
    const EnableLocKeysLanguage = getFeatureFlagValue(Features.EnableLocKeysLanguage.name);

    return (
        (!EnableLocKeysLanguage || useStrict) && locale?.toLowerCase() === 'keys' ? 'en' : locale
    ) as LanguageCulture;
};

export const IntlProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const isGov = window.env?.ENVIRONMENT?.includes('gov');
    const [ locale, setLocale ] = useState(isGov ? defaultLanguage : getUserLanguageFromLocalStorage());
    const [ route, setRoute ] = useState('');

    const [ messages, setMessages ] = useState<Record<string, string>>(en);

    useEffect(() => {
        (async () => {
            const EnableLocKeysLanguage = getFeatureFlagValue(Features.EnableLocKeysLanguage.name);

            if (locale?.toLowerCase() === 'keys') {
                processLocaleKeys(EnableLocKeysLanguage, setMessages, en);
                return;
            }
            const routeFileNamePortion = route ? `-${route}` : '';
            const localeData = await import(/* webpackChunkName: "[request]" */ `./translation${routeFileNamePortion}-${locale}.json`);
            const newMessages = Object.keys(localeData).reduce((acc: Record<string, string>, key: string) => {
                acc[key] = localeData[key] || (en as Record<string, string>)[key] || key;
                return acc;
            }, { ...en });

            setMessages(newMessages);
        })();
    }, [ locale, route ]);

    const intlShape = useMemo(() => {
        if (!messages) {
            return null;
        }

        return createIntl(
            {
                locale: locale?.toLowerCase() === 'keys' ? 'en' : locale,
                defaultLocale: 'en',
                messages,
            },
            cache,
        );
    }, [ locale, messages ]);

    return intlShape ?
        <IntlWrapperContext.Provider value={{
            locale,
            setLocale,
            setRoute,
        }}>
            <RawIntlProvider value={intlShape}>
                {children}
            </RawIntlProvider>
        </IntlWrapperContext.Provider> : null;
};

export function mapAutomationCloudLanguageToStripe(automationCloudLanguageCode: LanguageCulture) {
    switch (automationCloudLanguageCode) {
        case 'ja':
        case 'en':
        case 'fr':
        case 'de':
        case 'es':
        case 'pt-BR':
        case 'pt':
        case 'ru':
        case 'zh-TW':
            return automationCloudLanguageCode;
        case 'es-MX':
            return 'es';
        case 'zh-CN':
            return 'zh';
        default:
            return 'en';
    }
}

