import createStyles from '@mui/styles/createStyles';

export default createStyles({
    styleOverrides: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        },
    },
});
