import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { UiLoader } from '../UiLoader';
import { UiSignupLayoutRevamp } from '../UiSignupLayout/UiSignupLayoutRevamp';
import { UiText } from '../UiText/UiText';

const useStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90%',
            width: '100%',
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '552px',
            textAlign: 'center',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '24px',
            fontFamily: 'poppins',
            lineHeight: '32px',
        },
        bodyText: {
            fontWeight: 400,
            fontSize: '14px',
        },
        spacer: { marginBottom: '24px' },
    }),
);

export const UiNotificationPage = ({
    headerText, incorrectNavText, buttonName, buttonMethod,
}: { headerText: string; incorrectNavText?: string; buttonName?: string; buttonMethod?: any }) => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <UiSignupLayoutRevamp>
            <div
                className={classes.mainContainer}
                data-cy="notification-page-text">
                <span className={classes.infoContainer}>
                    <UiLoader />
                    <UiText className={clsx(classes.headerText, classes.spacer)}>
                        {translate({ id: incorrectNavText ? incorrectNavText : headerText })}
                    </UiText>
                    {buttonName &&
                    <Button
                        variant='contained'
                        onClick={buttonMethod}
                        data-cy='notification-page-button'>
                        {translate({ id: buttonName })}
                    </Button>}
                </span>
            </div>
        </UiSignupLayoutRevamp>
    );
};
