import createStyles from '@mui/styles/createStyles';

export default createStyles({
    styleOverrides: {
        root: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            width: '100%',
        },
    },
});
