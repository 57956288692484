import { TelemetryNamespace } from '@experiences/constants';
import { sanitizeUrl } from '@uipath/portal-shell-util';
import type { SeverityLevel } from '@uipath/telemetry-client-web';

import type { TrackMap } from './events/TrackMap';
import { getTracker } from './tracker';

export interface IEventTelemetry {
    name: keyof TrackMap;
    properties?: Record<string, any>;
}

export interface ITraceTelemetry {
    message: string;
    severityLevel?: SeverityLevel;
    properties?: Record<string, any>;
}

function getIsTelemetryDisabled() {
    return window.env?.APPLICATION_INSIGHTS_ENABLED === 'false' ||
        (!window.env?.APPLICATION_INSIGHTS_CONNECTION_STRING && !window.env?.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY);
}

export function initializeTelemetry() {
    const userLocale = localStorage.getItem('PORTAL_LOC_CONTEXT') ?? document.documentElement.lang;
    const isLocal = window.location.origin.includes('localhost');

    getTracker().initialize({
        isDevelopment: isLocal,
        language: userLocale,
        isPageViewTrackingEnabled: true,
        isPageViewPerformanceTrackingEnabled: true,
        pageViewTrackingOptions: {
            name: document.title,
            uri: sanitizeUrl(location.href),
        },
    });

    window.addEventListener('popstate', () => getTracker().trackNavigation({
        targetRoute: location.pathname,
        invocationSource: 'history',
    }));
}

function trackEvent(event: IEventTelemetry) {
    if (getIsTelemetryDisabled()) {
        return;
    }

    getTracker().trackEvent(event.name, event.properties);
}

function trackTrace(trace: ITraceTelemetry) {
    if (getIsTelemetryDisabled()) {
        return;
    }

    getTracker().trackTrace(trace.message, trace.severityLevel, trace.properties);
}

function trackApplicationStart(details?: Record<string, any>) {
    if (getIsTelemetryDisabled()) {
        return;
    }

    getTracker().trackApplicationStart(details);
}

function setMetadata(metadata: Record<string, any>) {
    if (getIsTelemetryDisabled()) {
        return;
    }

    getTracker().setMetadata(metadata);
}

function trackLogin(details: {
    authenticationMethod: string;
    [key: string]: any;
}) {
    if (getIsTelemetryDisabled()) {
        return;
    }

    getTracker().trackLogin(details);
}

window.addEventListener(TelemetryNamespace.Portal.TrackTrace, (event: Event) => {
    const customEvent = event as CustomEvent;
    if (customEvent.detail) {
        trackTrace({
            message: customEvent.detail.message,
            severityLevel: customEvent.detail.severityLevel,
            properties: customEvent.detail.properties,
        });
    }
});

export const portalTelemetry = {
    trackApplicationStart,
    setMetadata,
    trackLogin,
    trackEvent,
    trackTrace,
};
