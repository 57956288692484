import { getDeploymentVersion } from '@experiences/util';
import { GlobalEventNames } from '@uipath/portal-shell-util';
import type { ApplicationInsightDriverProps } from '@uipath/telemetry-client-web';
import {
    AppInsightDriverBuilder,
    DeploymentType,
    Tracker as TrackerBase,
    WellKnownApplication,
} from '@uipath/telemetry-client-web';
import type { IPageViewTelemetry } from '@uipath/telemetry-client-web/models';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import type { TrackMap } from './events/TrackMap';

const emailPiiRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+/g;
const emailPiiMask = '<PII_EMAIL>';

// For pageview events that has token, we don't want to log them as they have the token.
// calls to /unregistered are cluttering up app insights instances, so we don't log those either
const AI_FILTER_URI_LIST = [ 'token', 'unregistered' ];

export function getConfig() {
    const connectionString = window.env?.APPLICATION_INSIGHTS_CONNECTION_STRING ?? '';
    const instrumentationKey = window.env?.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY ?? '';

    return {
        instrumentationKey,
        connectionString,
        disableExceptionTracking: true,
        namePrefix: 'portal',
    } satisfies ApplicationInsightDriverProps;
}

export function getDeploymentType() {
    const env = process.buildConfigs.name;
    switch (env) {
        case 'k8s':
            return DeploymentType.AutomationSuite;
        case 'msi':
            return DeploymentType.Standalone;
        default:
            return DeploymentType.AutomationCloud;
    }
}

// TODO: @uipath/telemetry-client-web should re-export ITelemetryItem
const telemetryInitializer = (item: any) => {
    try {
        if (item?.name?.endsWith('Pageview')) {
            const refUri = item?.baseData && item.baseData['refUri'];
            if (refUri && AI_FILTER_URI_LIST.some(uri => refUri.toString().indexOf(uri) > -1)) {
                return false;
            }
        }

        item.baseData = item.baseData ?? {};
        item.baseData.properties = item.baseData.properties || {};
        item.baseData.properties['Canary'] = window.env.CANARY;
        item.baseData.properties['ScaleUnitIndex'] = window.env.SCALE_UNIT_INDEX;
        item.baseData.properties['ScaleUnitId'] = window.env.SCALE_UNIT_ID;
        item.baseData.properties['Region'] = window.env.REGION;

        item.tags = item.tags ?? {};

        const cloudRole = [
            'portal-fe',
            window.env.ENVIRONMENT,
            window.env.REGION,
            window.env.SCALE_UNIT_INDEX,
        ].filter(Boolean).join('-');
        item.tags['ai.cloud.role'] = cloudRole;

        // Remove the authorization header from the telemetry data that may be on an axios error
        delete item.data?.error?.config?.headers?.Authorization;

        if (window.env?.APPLICATION_INSIGHTS_APPLICATION_NAME) {
            item.baseData.properties['ApplicationName'] = window.env?.APPLICATION_INSIGHTS_APPLICATION_NAME;
        }

        const itemJson = JSON.stringify(item);
        const isEmailPiiFound = itemJson.search(emailPiiRegex) > -1;
        if (isEmailPiiFound) {
            const sanitizedItemString = itemJson.replace(emailPiiRegex, emailPiiMask);
            const itemObj = JSON.parse(sanitizedItemString);
            Object.assign(item, itemObj);
        }
        return true;
    } catch (e) {
        return false;
    }
};

export class Tracker extends TrackerBase<TrackMap> {
    override initialize(config: {
        isDevelopment: boolean;
        language: string;
        isPageViewTrackingEnabled: boolean;
        isPageViewPerformanceTrackingEnabled: boolean;
        pageViewTrackingOptions?: IPageViewTelemetry;
        initializer?: ((ev: any) => boolean | undefined);
    }) {
        super.initialize(config);

        super.setMetadata({
            trackerInstanceId: uuidv4(),
            deploymentVersion: getDeploymentVersion(),
            productVersion: window.env?.CLOUDRPA_VERSION,
            GAId: Cookies.get('_ga'),
        });

        const logger = (window.PortalShell?.Logger as any)?.appInsights;
        if (logger) {
            super.setPlatformInfo({
                ...logger.getPlatformInfo(),
                productVersion: window.env?.CLOUDRPA_VERSION,
            });
        }

        window.addEventListener(GlobalEventNames.platformInfo, (event: Event) => {
            super.setPlatformInfo({
                ...(event as CustomEvent).detail,
                productVersion: window.env?.CLOUDRPA_VERSION,
            });
        });
    }
}

let tracker: Tracker | undefined;

export const getTracker = () => {
    if (tracker) {
        return tracker;
    }

    const driver = AppInsightDriverBuilder
        .withConfig(getConfig())
        .build();

    driver.addTelemetryInitializer(telemetryInitializer);

    tracker = new Tracker(
        getDeploymentType(),
        process.buildConfigs.name === 'k8s' ? WellKnownApplication.AutomationSuite : WellKnownApplication.AutomationCloud,
        driver,
    );

    return tracker;
};
