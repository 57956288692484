import {
    useEffect,
    useState,
} from 'react';

export const promptEvent = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
};

const initBeforeUnLoad = (showExitPrompt: boolean) => {
    showExitPrompt
        ? window.addEventListener('beforeunload', promptEvent)
        : window.removeEventListener('beforeunload', promptEvent);
};

export function useExitPrompt(showPrompt: boolean) {
    const [ showExitPrompt, setShowExitPrompt ] = useState(showPrompt);
    window.onload = function() {
        initBeforeUnLoad(showExitPrompt);
    };

    useEffect(() => {
        initBeforeUnLoad(showExitPrompt);
    }, [ showExitPrompt ]);

    return {
        showExitPrompt,
        setShowExitPrompt,
    };
}
