import type { IRequestProps } from '@experiences/interfaces';
import pickBy from 'lodash/pickBy';

export const getRequestBody = (requestProps?: IRequestProps) =>
    requestProps?.body ? (requestProps?.isFormData ? requestProps.body : JSON.stringify(requestProps?.body)) : {};

function isEnumerableParam(param: [string, unknown]): param is [string, any[]] {
    return Array.isArray(param[1]);
}

function removeKeysWithUndefinedValues(obj: any): any {
    return pickBy(obj, value => value !== undefined);
}

export const getRequestParams = (urlParams?: any, paginationParams?: any, shouldEnumerate = true) => {
    const enumerableRequestParams =
    typeof urlParams === 'object' ? Object.entries(urlParams).filter(isEnumerableParam) : [];
    const nonEnumerableRequestParams =
    typeof urlParams === 'object' ? Object.entries(urlParams).filter(param => !isEnumerableParam(param)) : [];
    const requestParams = removeKeysWithUndefinedValues({
        ...Object.fromEntries(nonEnumerableRequestParams),
        ...paginationParams,
    });

    const queryParams = new URLSearchParams(requestParams);
    // Append enumerable request params with same key to support server logic.
    enumerableRequestParams.forEach(param => {
        if (shouldEnumerate) {
            param[1].forEach(value => {
                if (value !== undefined) {
                    queryParams.append(param[0], value);
                }
            });
        } else {
            queryParams.append(param[0], param[1].join(','));
        }
    });

    const queryParamsString = queryParams.toString();
    return queryParamsString ? `?${queryParamsString}` : '';
};

export async function throwableFetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const response = await fetch(input, init);
    if (response.ok) {
        return response;
    }
    throw response;
}
