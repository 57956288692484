import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export function useFormKeysValidation(keys: string[]) {
    const {
        setValue, clearErrors,
    } = useFormContext();

    useEffect(() => {
        clearErrors();
        setValue('activeKeys', keys, { shouldValidate: true });
    }, [ keys, clearErrors, setValue ]);

    return null;
}
