import type { IUiLinkMap } from '@experiences/interfaces';

const domain = 'https://docs.aws.amazon.com';
const path = 'AWSSimpleQueueService/latest/SQSDeveloperGuide/sqs-setting-up.html#sqs-getting-access-key-id-secret-access-key';

export const SqsConnectionsString: IUiLinkMap = new Map([
    [ 'en', `${domain}/${path}` ],
    [ 'zh-CN', `${domain}/zh_cn/${path}` ],
    [ 'zh-TW', `${domain}/zh_tw/${path}` ],
    [ 'fr', `${domain}/fr_fr/${path}` ],
    [ 'de', `${domain}/de_de/${path}` ],
    [ 'ja', `${domain}/ja_jp/${path}` ],
    [ 'es', `${domain}/es_es/${path}` ],
    [ 'pt-BR', `${domain}/pt_br/${path}` ],
    [ 'ko', `${domain}/ko_kr/${path}` ],
]);
