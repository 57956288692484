import type { IFeature } from './FeatureFlagConstants';
import { Features } from './FeatureFlagConstants';

const allowedLocalStorageFlags = [
    Features.EnableUnifiedAuditExperience.name,
    Features.EnableAzureB2CAuthentication.name,
    Features.EnableNewAccountAndGroupsUX.name,
];

export function getAllFeatureFlags() {
    const allFlags = Object.values(Features).filter((f: IFeature) => f.frontend && typeof f.defaultValue === 'boolean');
    allFlags.sort((a, b) => a.name.localeCompare(b.name));
    return allFlags.map(flag => {
        const envFlag = getEnvFeatureFlag(flag.name);
        return {
            ...flag,
            description: `default value: ${flag.defaultValue} from: ${envFlag?.source}`,
            defaultValue: envFlag?.value ?? flag.defaultValue,
            overridden: getLocalStorageFlagValue(flag.name) !== undefined,
            value: getFeatureFlagValue(flag.name),
        };
    });
}

export function getFeatureFlagValue(featureFlagKey: string): boolean {
    const flagValue = getLocalStorageFlagValue(featureFlagKey);
    if (flagValue !== undefined) {
        return flagValue.toLowerCase() === 'true';
    }

    const envFlagValue = getEnvFeatureFlagValue(featureFlagKey);
    if (typeof envFlagValue === 'string') {
        return envFlagValue.toLowerCase() === 'true';
    }

    return envFlagValue === true;
}

export function getFeatureFlagConfig<T = string>(featureFlagKey: string): string | T {
    const flagValue = getLocalStorageFlagValue(featureFlagKey);
    if (flagValue !== undefined) {
        return flagValue;
    }

    return getEnvFeatureFlagValue(featureFlagKey) as T ?? '';
}

export function setLocalFeatureFlagValue(featureFlagKey: string, value: string) {
    if (getIsLocalStorageFeatureFlagsEnabled(featureFlagKey)) {
        let isRemoved = false;
        const defaultValue = getEnvFeatureFlagValue(featureFlagKey);
        if (typeof defaultValue === 'boolean' && defaultValue.toString() === value) {
            localStorage.removeItem(featureFlagKey);
            isRemoved = true;
        } else if (typeof defaultValue === 'string' && defaultValue === value) {
            localStorage.removeItem(featureFlagKey);
            isRemoved = true;
        } else {
            localStorage.setItem(featureFlagKey, value);
        }

        const event = new StorageEvent('storage', {
            key: featureFlagKey,
            newValue: isRemoved ? null : value,
        });

        window.dispatchEvent(event);
    }
}

function getLocalStorageFlagValue(featureFlagKey: string): string | undefined {
    if (getIsLocalStorageFeatureFlagsEnabled(featureFlagKey)) {
        return localStorage.getItem(featureFlagKey) ?? undefined;
    }
    return undefined;
}

function getEnvFeatureFlagValue(featureFlagKey: string): string | boolean | any | undefined {
    return getEnvFeatureFlag(featureFlagKey)?.value;
}

function getEnvFeatureFlag(featureFlagKey: string): { value: any; source: string } | undefined {
    if (typeof window === 'undefined') {
        return undefined;
    }

    return window.env?.FEATURE_FLAGS?.[featureFlagKey];
}

export function getIsLocalStorageFeatureFlagsEnabled(featureFlagKey: string) {
    return isLocalFeatureFlagsEnabled || allowedLocalStorageFlags.includes(featureFlagKey);
}

export const isLocalFeatureFlagsEnabled: boolean =
    typeof window !== 'undefined' && typeof localStorage !== 'undefined' && (
        (window as any).Cypress ||
        localStorage.getItem(Features.EnableLocalStorageFeatureFlags.name) === 'true' ||
        localStorage.getItem('diagnostics-flags') === 'true'
    );
