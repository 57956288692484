import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { UiText } from '../UiText/UiText';

const useStyles = makeStyles(theme =>
    createStyles({
        container: { textAlign: 'center' },
        link: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': { textDecoration: 'underline' },
        },
    }),
);

interface IUiTextLink {
    text: string;
    link: string;
    onClick: () => any;
    dataCy?: string;
    className?: string;
}

export const UiTextLink = ({
    text, link, onClick, dataCy, className,
}: IUiTextLink) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={clsx(classes.container, className)}>
            <UiText display="inline">
                {translate({ id: text })}
            &nbsp;
            </UiText>
            <UiText
                display="inline"
                className={classes.link}
                onClick={onClick}
                data-cy={dataCy}
                tabIndex={0}>
                {translate({ id: link })}
            </UiText>
        </div>
    );
};
