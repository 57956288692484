import { ENVIRONMENT } from '@experiences/constants';

export function getEnvironment() {
    if (window.location.origin.toLowerCase().indexOf('localhost') >= 0) {
        return ENVIRONMENT.LOCAL;
    }
    return window.env?.ENVIRONMENT;
}

// Return variable value found in envJS
export function getEnvVariableValue(envKey: string): any {
    if (typeof window === 'undefined') {
        return undefined;
    }

    if (window.env?.[envKey] === undefined) {
        // eslint-disable-next-line no-console
        console.error(`Failed to get environment variable with key "${envKey}"`);
    }
    return window.env?.[envKey];
}
