/* eslint-disable @typescript-eslint/prefer-enum-initializers */
// DO NOT MODIFY: Enum is used for comparison of various license types

export enum AccountLicense {
    'UNDEFINED' = -1,
    'ENTERPRISE',
    'TRIAL',
    'PRO-TRIAL',
    'PRO',
    'COMMUNITY',
    'FREEKIT',
    'UNLICENSED',
}
