import { GenericErrorMessages } from '@experiences/constants';
import type { IUiLinkMap } from '@experiences/interfaces';
import { getBrowserLanguage } from '@experiences/locales';
import React from 'react';
import type { RouteObject } from 'react-router-dom';

function getLocalizedLink(linkMap: IUiLinkMap) {
    const language = getBrowserLanguage();
    return linkMap.get(language) ?? linkMap.get('en');
}

export class UiErrorBoundary extends React.Component<
{ route?: RouteObject; errorComponent?: React.ReactNode; children?: React.ReactNode },
{ hasError: boolean }
> {
    constructor(props: { route?: RouteObject; errorComponent?: React.ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidUpdate(prevProps: { route?: RouteObject; errorComponent?: React.ReactNode }) {
        if (!Object.is(prevProps.route, this.props.route)) {
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.errorComponent ?? getLocalizedLink(GenericErrorMessages);
        }

        return this.props.children;
    }
}
