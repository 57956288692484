export enum FirstPartyServiceRedirect {
    AUTOMATION_HUB = 'automationhub',
    MARKETPLACE = 'marketplace',
    ACADEMY = 'academy',
    CUSTOMER_PORTAL = 'customerportal',
    STUDIO = 'studio',
    INTEGRATION_SERVICE = 'connections',
    PROCESS_MINING = 'processmining',
    DOCS = 'docs',
}

/**
 * @deprecated can be removed once marketplace consumes changes
 */
export const MARKETPLACE_STORAGE_KEY = 'marketplace_redirect_uri';

export const SERVICE_REDIRECT_URI_KEY = 'service_redirect_uri';
export const SERVICE_REDIRECT_SERVICE_KEY = 'service_redirect';
export const SERVICE_REDIRECT_TENANT_ID_KEY = 'service_tenant_id';

export const SERVICE_REDIRECT_SERVICE_LEGACY_KEY = 'serviceKey';
export const SERVICE_REDIRECT_TENANT_ID_LEGACY_KEY = 'tenantId';

export const isValidServiceRedirect = (service?: string | null) =>
    service && Object.values<string>(FirstPartyServiceRedirect).includes(service);

interface FirstPartyServiceRedirectOptions {
    tenantLevelService: boolean;
    includeSearch: boolean;
}

export const firstPartyServiceRedirectOptions: Record<FirstPartyServiceRedirect, FirstPartyServiceRedirectOptions> = {
    [FirstPartyServiceRedirect.AUTOMATION_HUB]: {
        tenantLevelService: true,
        includeSearch: true,
    },
    [FirstPartyServiceRedirect.MARKETPLACE]: {
        tenantLevelService: false,
        includeSearch: false,
    },
    [FirstPartyServiceRedirect.ACADEMY]: {
        tenantLevelService: false,
        includeSearch: false,
    },
    [FirstPartyServiceRedirect.CUSTOMER_PORTAL]: {
        tenantLevelService: false,
        includeSearch: false,
    },
    [FirstPartyServiceRedirect.STUDIO]: {
        tenantLevelService: false,
        includeSearch: false,
    },
    [FirstPartyServiceRedirect.INTEGRATION_SERVICE]: {
        tenantLevelService: true,
        includeSearch: false,
    },
    [FirstPartyServiceRedirect.PROCESS_MINING]: {
        tenantLevelService: true,
        includeSearch: false,
    },
    [FirstPartyServiceRedirect.DOCS]: {
        tenantLevelService: false,
        includeSearch: false,
    },
};
