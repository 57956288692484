import throttle from 'lodash/throttle';
import type React from 'react';
import {
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

const TENANT_SHELL_WIDTH = 312;

export const useWindowSize = () => {
    const [ size, setSize ] = useState([ 0, 0 ]);
    const throttled = useRef(throttle(([ width, height ]: [number, number]) => setSize([ width, height ]), 200));

    useEffect(() => {
        const isClient = typeof window === 'object';

        if (!isClient) {
            return;
        }

        function updateSize() {
            throttled.current([ window.innerWidth, window.innerHeight ]);
        }

        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
};

const usePrevPropsAndState = (props?: any, state?: any) => {
    const prevPropsAndStateRef = useRef({
        props: null,
        state: null,
    });
    const prevProps = prevPropsAndStateRef.current.props;
    const prevState = prevPropsAndStateRef.current.state;

    useEffect(() => {
        prevPropsAndStateRef.current = {
            props,
            state,
        };
    });

    return {
        prevProps,
        prevState,
    };
};

export const useGetSnapshotBeforeUpdate = (cb?: any, props?: any, state?: any) => {
    const {
        prevProps, prevState,
    } = usePrevPropsAndState(props, state);

    const snapshot = useRef(null);
    const componentJustMounted = useRef(true);

    useLayoutEffect(() => {
        if (!componentJustMounted.current) {
            snapshot.current = cb(prevProps, prevState);
        }
        componentJustMounted.current = false;
    });

    const useComponentDidUpdate = (callback: any) => {
        useEffect(() => {
            if (!componentJustMounted.current) {
                callback(prevProps, prevState, snapshot.current);
            }
        });
    };

    return useComponentDidUpdate;
};

export const useElementDimensions = () => {
    const elementRef = useRef<any>(null);

    const [ elementSize, setElementSize ] = useState({
        width: 0,
        height: 0,
    });

    const useComponentDidUpdate = useGetSnapshotBeforeUpdate(() => ({
        width: elementRef.current?.offsetWidth,
        height: elementRef.current?.offsetHeight,
    }));

    useComponentDidUpdate(() => {
        if (elementRef?.current) {
            const width = elementRef.current?.offsetWidth;
            const height = elementRef.current?.offsetHeight;

            setElementSize({
                width,
                height,
            });
        }
    });

    return [ elementSize, elementRef ] as [{ height: number; width: number }, React.MutableRefObject<any>];
};

export const useElementWidth = () => {
    const elementRef = useRef<any>(null);

    const [ elementWidth, setElementWidth ] = useState(window.innerWidth - TENANT_SHELL_WIDTH);

    const useComponentDidUpdate = useGetSnapshotBeforeUpdate(() => ({
        width: elementRef.current?.offsetWidth || window.innerWidth - TENANT_SHELL_WIDTH,
        height: elementRef.current?.offsetHeight || window.innerHeight - TENANT_SHELL_WIDTH,
    }));

    useComponentDidUpdate(() => {
        if (elementRef?.current) {
            const tableWidth = elementRef.current?.offsetWidth || window.innerWidth - TENANT_SHELL_WIDTH;

            setElementWidth(tableWidth);
        } else {
            setElementWidth(window.innerWidth - TENANT_SHELL_WIDTH);
        }
    });

    return [ elementWidth, elementRef ] as [number, React.MutableRefObject<any>];
};

export const useElementHeight = () => {
    const elementRef = useRef<any>(null);

    const [ elementHeight, setElementHeight ] = useState(0);

    const useComponentDidUpdate = useGetSnapshotBeforeUpdate(() => ({
        width: elementRef.current?.offsetWidth,
        height: elementRef.current?.offsetHeight,
    }));

    useComponentDidUpdate(() => {
        if (elementRef?.current) {
            const height = elementRef.current?.offsetHeight;

            setElementHeight(height);
        }
    });

    return [ elementHeight, elementRef ] as [number, React.MutableRefObject<any>];
};

export const useInterval = (callback: () => any, delay: number | null) => {
    const savedCallback = useRef<() => any>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [ callback ]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current?.();
        }

        if (delay != null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }

        return () => void 0;
    }, [ delay ]);
};

export const useFirstRender = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};
