import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ApLoader } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        fullPage: { height: '100vh' },
        backdrop: { zIndex: 999999 },
    }),
);

export const UiLoader = ({ type }: { type?: 'backdrop' | 'full' }) => {
    const classes = useStyles();

    if (type === 'backdrop') {
        return (
            <Backdrop
                data-cy="ui-loader-backdrop"
                data-testid="ui-loader-backdrop"
                open
                className={classes.backdrop}>
                <ApLoader data-testid="ap-loader" />
            </Backdrop>
        );
    }

    return (
        <div
            data-cy="ui-loader"
            data-testid="ui-loader"
            className={clsx(classes.root, type === 'full' ? classes.fullPage : '')}>
            <ApLoader data-testid="ap-loader" />
        </div>
    );
};
