import './index.css';

import { initializeTelemetry } from '@experiences/telemetry';
import { GlobalEventNames } from '@uipath/portal-shell-util';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';

initializeTelemetry();

const event = new CustomEvent(GlobalEventNames.platformInfo, {
    detail: {
        cloudUserId: 'global-app',
        cloudOrganizationId: 'global-app',
        cloudTenantId: 'global-app',
        sessionId: 'global-app',
    },
});

window.dispatchEvent(event);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);
