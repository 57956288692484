import { TelemetryNamespace } from '@experiences/constants';
import { useCallback } from 'react';
import type {
    NavigateOptions,
    To,
} from 'react-router';
import {
    matchPath,
    useNavigate,
    useParams,
} from 'react-router';
import urljoin from 'url-join';

import { useGetRoutes } from '../global/UiRoutesProvider';
import { replaceRouteParams } from './RouteParamReplacer';
import { useRouteResolver } from './useRouteResolver';

export const prependTo = (to: To, prependAccount: boolean) => {
    let prependedTo = to;
    if (prependAccount) {
        if (
            typeof prependedTo === 'string'
            && !prependedTo.includes('/portal_')
        ) {
            prependedTo = urljoin('/:accountName/portal_', prependedTo);
        } else if (
            typeof prependedTo !== 'string'
            && prependedTo.pathname
            && !prependedTo.pathname.includes('/portal_')
        ) {
            prependedTo = {
                ...prependedTo,
                pathname: urljoin('/:accountName/portal_', prependedTo.pathname),
            };
        }
    }
    return prependedTo;
};

export const useNavigateWithParams = (option: { prependAccount?: boolean } = { prependAccount: process.buildConfigs.prependAccount }) => {
    // eslint-disable-next-line ban/ban
    const navigate = useNavigate();
    const getRoute = useRouteResolver();
    const params = useParams();

    const { routeMappings } = useGetRoutes();

    return useCallback((to: To | number, options?: NavigateOptions & { prependAccount?: boolean } | undefined) => {
        if (typeof to === 'number') {
            navigate(to);
            return;
        }
        let matchedParams = params;
        const targetTo = getRoute(replaceRouteParams(to, params));
        const matchedRoute = Object.entries(routeMappings).find(([ key ]) => {
            const matchedPath = matchPath(
                key,
                typeof targetTo === 'string'
                    ? targetTo
                    : targetTo.pathname ?? '',
            );
            if (matchedPath) {
                matchedParams = matchedPath.params;
            }
            return matchedPath;
        });
        if (matchedRoute) {
            const message = `Redirecting from ${typeof targetTo === 'string'
                ? targetTo
                : targetTo.pathname} to ${matchedRoute[1]}`;
            // eslint-disable-next-line no-console
            console.log(message);
            window.dispatchEvent(new CustomEvent(TelemetryNamespace.Portal.TrackTrace, { detail: { message } }));
            to = typeof targetTo === 'string'
                ? matchedRoute[1]
                : {
                    ...targetTo,
                    pathname: matchedRoute[1],
                };
        }

        const prependedTo = prependTo(to, options?.prependAccount ?? option.prependAccount ?? false);
        navigate(getRoute(replaceRouteParams(prependedTo, matchedParams)), options);
    },
    [ getRoute, navigate, option.prependAccount, params, routeMappings ],
    );
};
