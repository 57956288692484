import React, {
    useEffect,
    useState,
} from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';

async function getRouter() {
    const isGovCloud = window.location.origin.includes('gov') || window.env?.ENVIRONMENT?.includes('gov');

    // explicitly import each route object to avoid warning about expression for dynamic imports
    if (isGovCloud) {
        const { govRoutes } = await import('./routes/routes.gov');

        return createBrowserRouter(govRoutes);
    }

    const { cloudRoutes } = await import('./routes/routes');

    return createBrowserRouter(cloudRoutes);
}

export const Router = () => {
    const [ router, setRouter ] = useState<any>(undefined);

    useEffect(() => {
        getRouter().then(r => setRouter(r));
    }, []);

    return router ? <RouterProvider router={router} /> : null;
};
