import unescape from 'lodash/unescape';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import xss from 'xss';

export function useFilteredParams(paramsToGet: string[], useWindowSearch = false, windowSearch = window.location.search) {
    const location = useLocation();

    const filteredParams = useMemo(() => {
        const search = unescape(useWindowSearch ? windowSearch : location.search);
        const params = new URLSearchParams(search);
        return paramsToGet.map(p => xss(decodeURIComponent(params.get(p) ?? '')));
    }, [ useWindowSearch, windowSearch, location.search, paramsToGet ]);

    return filteredParams;
}
