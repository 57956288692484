import Link from '@mui/material/Link';
import React from 'react';
import { useIntl } from 'react-intl';

import { UiText } from '../UiText/UiText';

export const UiCheckBoxLabel: React.FC<{
    className?: string;
    href: string[];
    hrefText: string[];
    label?: string;
    style?: any;
    dataCy?: string;
}> = ({
    className, label, href, hrefText, style, dataCy,
}) => {
    const { formatMessage: translate } = useIntl();

    const links: Record<number, any> = {};
    href.forEach(
        (link, index) =>
            (links[index] = (
                <Link
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid={`ui-checkbox-label-link-${index}`}
                    color="primary">
                    {translate({ id: hrefText[index] })}
                </Link>
            )),
    );

    return (
        <UiText
            className={className}
            style={style}
            data-cy={dataCy}>
            {translate({ id: label }, links)}
        </UiText>
    );
};
