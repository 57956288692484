import type {
    IExperiment,
    IExperiments,
} from '@experiences/interfaces';

export const abTestingUrl = '/api/abTesting';

export const Experiments: IExperiments = {

    UnitTestExperiment: {
        key: 'unit-test-experiment',
        layer: 'unit-test-layer',
        parameters: {
            unitTestParameter: {
                key: 'unit-test-parameter',
                defaultValue: 'CONTROL',
            },
        },
    },
};

export const getExperimentByKey = (experimentKey: string) =>
    Object.values(Experiments).find(experiment => experiment.key === experimentKey);

export const getParameterByKey = (parameterKey: string, experiment: IExperiment) =>
    Object.values(experiment.parameters).find(parameter => parameter.key === parameterKey);
