import type { IUiDialogHookParams } from '@experiences/interfaces';

export class DialogHandler {
    name: string;
    handlingFunction: (dialogParameters: IUiDialogHookParams) => void;

    constructor(name: string, handlingFunction: (dialogParameters: IUiDialogHookParams) => void) {
        this.name = name;
        this.handlingFunction = handlingFunction;
    }
}

class DialogCoordinator {
    handlers: DialogHandler[];

    constructor() {
        this.handlers = [];
    }

    showDialog(dialogParameters: IUiDialogHookParams) {
        this.handlers.forEach(handler => handler.handlingFunction({ ...dialogParameters }));
    }

    addDialogHandler(dialogHandler: DialogHandler) {
        if (!this.handlers.find(handler => handler.name === dialogHandler.name)) {
            this.handlers.push(dialogHandler);
        }
    }

    removeDialogHandler(dialogHandlerName: string) {
        this.handlers = this.handlers.filter(handler => handler.name !== dialogHandlerName);
    }
}

const dialogCoordinatorSingleton = new DialogCoordinator();
export default dialogCoordinatorSingleton;
