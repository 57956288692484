import CheckIcon from '@mui/icons-material/Check';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import type { ButtonTypeMap } from '@mui/material/Button';
import type { ExtendButtonBase } from '@mui/material/ButtonBase';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        button: {
            width: 40,
            height: 40,
        },
        successIcon: {
            transform: 'scale(.8)',
            padding: 0,
            color: theme.palette.semantic.colorSuccessIcon,
        },
        copyButton: {
            transform: 'scale(.8)',
            padding: 0,
            '&:hover': { color: theme.palette.semantic.colorPrimary },
        },
    }),
);

const CHECKED_ICON_ANIMATION_TIMEOUT = 2 * 1000; // 2 seconds

export const UiCopyButton: ExtendButtonBase<ButtonTypeMap<{ textToCopy: string }>> = (props: any) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ showCopied, setShowCopied ] = useState(false);

    const {
        textToCopy, ...rest
    } = props;

    const onCopy = useCallback(() => {
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), CHECKED_ICON_ANIMATION_TIMEOUT);
    }, []);

    return (
        <CopyToClipboard text={textToCopy}>
            <IconButton
                {...rest}
                className={clsx(classes.button, showCopied ? classes.successIcon : classes.copyButton)}
                onClick={onCopy}>
                {showCopied ? (
                    <Fade in>
                        <Tooltip
                            title={translate({ id: 'CLIENT_COPIED' })}
                            open>
                            <CheckIcon
                                data-cy="ui-copy-button-success-icon"
                                data-testid="ui-copy-button-success-icon" />
                        </Tooltip>
                    </Fade>
                ) : (
                    <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                        <FileCopyOutlinedIcon
                            data-cy="ui-copy-button-copy-icon"
                            data-testid="ui-copy-button-copy-icon" />
                    </Tooltip>
                )}
            </IconButton>
        </CopyToClipboard>
    );
};
