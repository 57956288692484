export enum PortalTagManagementEvent {
    CreateLabel = 'PortalTagManagement.CreateLabel',
    CreateProperty = 'PortalTagManagement.CreateProperty',
    EditLabel = 'PortalTagManagement.EditLabel',
    EditKeyDetails = 'PortalTagManagement.EditKeyDetails',
    EditValues = 'PortalTagManagement.EditValues',
    ListTaggedObjectsLabels = 'PortalTagManagement.ListTaggedObjectsLabels',
    ListTaggedObjectsProperty = 'PortalTagManagement.ListTaggedObjectsProperty',
    DeleteLabel = 'PortalTagManagement.DeleteLabel',
    DeleteProperty = 'PortalTagManagement.DeleteProperty',
    CardClick = 'PortalTagManagement.CardClick',
}
