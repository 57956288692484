import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import type { InputProps } from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { ReactNode } from 'react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import type {
    Control,
    FieldPath,
    FieldValues,
} from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        select: { height: '40px' },
        icon: { fill: theme.palette.semantic.colorIconDefault },
        disabled: { color: theme.palette.semantic.colorForegroundDisable },
        noneMenuItem: { fontStyle: 'italic' },
        labelName: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
    }),
);

interface IUiSelectProps<T extends FieldValues> extends InputProps {
    control: Control<T>;
    name: FieldPath<T>;
    inputLabel?: any;
    options: { [k: string]: string } | any[];
    dataCy: string;
    helperText?: string | React.ReactNode;
    isTranslated?: boolean;
    tooltip?: ReactNode;
}

const InternalUiSelect = <T extends FieldValues>({
    control,
    name,
    inputLabel,
    options,
    dataCy,
    error,
    disabled,
    helperText,
    className,
    style,
    fullWidth,
    required,
    isTranslated,
    onChange,
    tooltip,
    forwardedRef,
}: IUiSelectProps<T> & { forwardedRef: React.ForwardedRef<any> }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const mapEnglishToTranslated = useCallback(
        (englishToI18nCode: { [k: string]: string }) => {
            const englishToTranslationMap: { [k: string]: string } = {};
            Object.entries(englishToI18nCode).forEach(([ key, value ]) => {
                englishToTranslationMap[key] = translate({ id: value });
            });
            return englishToTranslationMap;
        },
        [ translate ],
    );

    const optionsArray = useMemo(
        () =>
            Array.isArray(options)
                ? options.map(value => ({
                    key: value,
                    value,
                }))
                : isTranslated
                    ? Object.entries(options).map(([ key, value ]) => ({
                        key,
                        value,
                    }))
                    : Object.entries(mapEnglishToTranslated(options)).map(([ key, value ]) => ({
                        key,
                        value,
                    })),
        [ options, mapEnglishToTranslated, isTranslated ],
    );

    return (
        <FormControl
            ref={forwardedRef}
            error={error}
            disabled={disabled}
            className={className}
            style={style}
            fullWidth={fullWidth}
            variant="standard">
            {inputLabel && (
                <InputLabel
                    htmlFor={name}
                    id={`${name}-label`}
                    className="select-label"
                    required={required}
                    style={{
                        position: 'relative',
                        display: 'inline-flex',
                    }}>
                    <div className={classes.labelName} >
                        {inputLabel}
                        {tooltip}
                    </div>
                </InputLabel>
            )}
            <Controller
                control={control}
                name={name}
                rules={{ required }}
                render={({ field }) => (
                    <Select
                        {...field}
                        variant="outlined"
                        labelId={`${field.name}-label`}
                        inputProps={{
                            id: field.name,
                            name: field.name,
                            'data-cy': dataCy,
                            classes: {
                                icon: classes.icon,
                                disabled: classes.disabled,
                            },
                            style: {
                                height: '100%',
                                paddingTop: '0px',
                                paddingBottom: '0px',
                            },
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                field.onChange(e);
                                if (onChange) {
                                    onChange(e);
                                }
                            },
                        }}
                        className={classes.select}
                        IconComponent={ExpandMoreIcon}
                    >
                        {!required && (
                            <MenuItem
                                aria-label={translate({ id: 'CLIENT_NONE' })}
                                value=""
                                className={classes.noneMenuItem}>
                                {translate({ id: 'CLIENT_NONE' })}
                            </MenuItem>
                        )}
                        {optionsArray.map((option, i) => (
                            <MenuItem
                                key={i}
                                value={option.key}
                                lang={field.name === 'language' ? option.key : undefined}
                                aria-label={field.name === 'language' ? `${option.value} ${option.key.toUpperCase()}` : undefined}
                            >
                                {option.value}
                            </MenuItem>
                        ))}
                    </Select>
                )}

            />
            {helperText && <FormHelperText>
                {helperText}
            </FormHelperText>}
        </FormControl>
    );
};

const ForwardedUiSelect = React.forwardRef<any, IUiSelectProps<any>>((props, ref) => (
    <InternalUiSelect
        {...props}
        forwardedRef={ref} />
));

export const UiSelect = ForwardedUiSelect;
