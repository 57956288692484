import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import type { RouteObject } from 'react-router';
import urljoin from 'url-join';

export type UiRouteObject = Omit<RouteObject, 'children'> & {
    redirectUrls?: string[];
    children?: UiRouteObject[];
};

const RouteContext = React.createContext<{
    routeMappings: Record<string, string>;
    setRoutes: React.Dispatch<React.SetStateAction<UiRouteObject[]>>;
}>({
    routeMappings: {},
    setRoutes: () => {},
});

export const useGetRoutes = () => React.useContext(RouteContext);

export const RouteProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [ routes, setRoutes ] = React.useState<UiRouteObject[]>([]);

    const routeMappings = useMemo<Record<string, string>>(() => {
        const mappings: Record<string, string> = {};

        const mapRoutes = (routesObj: UiRouteObject[], parentPath = '') => {
            routesObj.forEach(route => {
                const fullPath = route.index || !route.path || route.path === parentPath
                    ? parentPath
                    : route.path.includes(parentPath)
                        ? route.path
                        : urljoin(parentPath, route.path);

                if (fullPath && route.redirectUrls) {
                    route.redirectUrls.forEach(redirectUrl => {
                        mappings[redirectUrl] = fullPath;
                    });
                }

                // If the route has children, recursively map them as well
                if (route.children) {
                    mapRoutes(route.children, fullPath);
                }
            });
        };

        mapRoutes(routes);
        return mappings;
    }, [ routes ]);

    return <RouteContext.Provider value={{
        routeMappings,
        setRoutes,
    }}>
        {children}
    </RouteContext.Provider>;
};
