import type {
    Params,
    To,
} from 'react-router';

export const replaceRouteParams = (route: To, params: Record<string, string> | Params) => {
    let parsedLink = route;
    Object.entries(params).forEach(([ key, value ]) => {
        if (value) {
            if (typeof parsedLink === 'string') {
                parsedLink = parsedLink.replace(`:${key}`, value);
            } else {
                parsedLink = {
                    ...parsedLink,
                    pathname: parsedLink.pathname?.replace(`:${key}`, value),
                };
            }
        }
    });
    return parsedLink;
};
