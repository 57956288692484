// All OMS regions
// From: https://github.com/UiPath/Cloud-RPA/blob/dev/net5/LocationService/src/LocationClient/LocationClientV2.cs#L654
export enum Region {
    None = 'None',
    Europe = 'Europe',
    UnitedStates = 'UnitedStates',
    Japan = 'Japan',
    Canada = 'Canada',
    Australia = 'Australia',
    GovVirginia = 'GovVirginia',
    Singapore = 'Singapore',
    GxPUnitedStates = 'GxPUnitedStates',
    India = 'India',
    UnitedKingdom = 'UnitedKingdom',
    GxPNorthEurope = 'GxPNorthEurope',
    Unknown = 'Unknown',
}

export const TranslatedRegionName: { [key in Region]: string } = {
    [Region.None]: 'CLIENT_NONE',
    [Region.Europe]: 'CLIENT_EUROPE',
    [Region.UnitedStates]: 'CLIENT_UNITED_STATES',
    [Region.Japan]: 'CLIENT_JAPAN',
    [Region.Canada]: 'CLIENT_CANADA',
    [Region.Australia]: 'CLIENT_AUSTRALIA',
    [Region.GovVirginia]: 'CLIENT_GOVVIRGINIA',
    [Region.Singapore]: 'CLIENT_SINGAPORE',
    [Region.GxPUnitedStates]: 'CLIENT_UNITED_STATES_GXP',
    [Region.India]: 'CLIENT_INDIA',
    [Region.UnitedKingdom]: 'CLIENT_UNITED_KINGDOM',
    [Region.GxPNorthEurope]: 'CLIENT_NORTH_EUROPE_GXP',
    [Region.Unknown]: 'CLIENT_NONE',
};

export function getRegionTranslationId(region?: Region | string): string {
    return TranslatedRegionName[region as Region || Region.None];
}
