import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import React, {
    useMemo,
    useState,
} from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            overflow: 'visible',
            borderWidth: 1,
            borderStyle: 'solid',
            boxShadow: 'none',
            display: 'flex',
            borderRadius: '0px',
            marginBottom: '10px',
        },
        enterpriseTrialCard: { marginBottom: '0px' },
        icon: {
            padding: '12px',
            maxHeight: '48px',
        },
        content: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '12px',
            paddingBottom: '12px',
            fontSize: '14px',
        },
    }),
);

export const UiAlertBanner: React.FC<PropsWithChildren<{
    icon?: React.ReactNode;
    type?: 'info' | 'warning' | 'error' | 'black';
    closeable?: boolean;
    enterpriseTrialAlertBar?: boolean;
    dataCy?: string;
}>> = ({
    icon, type, children, closeable = true, enterpriseTrialAlertBar, dataCy,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const typeBackgroundMapping = useMemo(
        () => ({
            info: theme.palette.semantic.colorInfoBackground,
            warning: theme.palette.semantic.colorWarningBackground,
            error: theme.palette.semantic.colorErrorBackground,
            black: theme.palette.semantic.colorBackgroundInverse,
        }),
        [ theme ],
    );

    const typeBorderMapping = useMemo(
        () => ({
            info: theme.palette.semantic.colorInfoForeground,
            warning: theme.palette.semantic.colorWarningIcon,
            error: theme.palette.semantic.colorErrorIcon,
            black: theme.palette.semantic.colorBackgroundInverse,
        }),
        [ theme ],
    );

    const typeIconMapping = useMemo(
        () => ({
            info: <InfoIcon style={{ color: theme.palette.semantic.colorInfoForeground }} />,
            warning: <WarningIcon style={{ color: theme.palette.semantic.colorWarningIcon }} />,
            error: <ErrorIcon style={{ color: theme.palette.semantic.colorErrorIcon }} />,
            black: null,
        }),
        [ theme ],
    );

    const [ closed, setClosed ] = useState(false);

    return !closed ? (
        <Card
            data-cy={dataCy}
            className={clsx(classes.card, { [classes.enterpriseTrialCard]: enterpriseTrialAlertBar })}
            style={{
                borderColor: type ? typeBorderMapping[type] : 'default',
                backgroundColor: type ? typeBackgroundMapping[type] : 'default',
            }}
        >
            <span className={classes.icon}>
                {icon ?? (type ? typeIconMapping[type] : null)}
            </span>
            <div className={classes.content}>
                {children}
            </div>
            {closeable && (
                <div>
                    <IconButton
                        onClick={() => {
                            setClosed(true);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
        </Card>
    ) : null;
};
