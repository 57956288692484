import CssBaseline from '@mui/material/CssBaseline';
import type { Theme } from '@mui/material/styles';
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material/styles';
import {
    apolloMaterialUiThemeDark as darkTheme,
    apolloMaterialUiThemeDarkHC as darkHighContrastTheme,
    apolloMaterialUiThemeLight as lightTheme,
    apolloMaterialUiThemeLightHC as lightHighContrastTheme,
} from '@uipath/apollo-mui5';
import type {
    ApolloThemeType,
    ThemeType,
} from '@uipath/portal-shell-util';
import {
    defaultTheme,
    getApolloThemeFromTheme,
    getCachedTheme,
    themeList,
} from '@uipath/portal-shell-util';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import GlobalStyles from './GlobalStyles';
import MuiTab from './MuiTab';
import MuiTable from './TableOverrides/MuiTable';
import MuiTableCell from './TableOverrides/MuiTableCell';
import MuiTableHead from './TableOverrides/MuiTableHead';
import MuiTablePagination from './TableOverrides/MuiTablePagination';
import MuiTableRow from './TableOverrides/MuiTableRow';

const themeProps = { MuiButton: { defaultProps: { disableElevation: true } } };

export const mergedLightTheme = createTheme(lightTheme, {
    components: {
        MuiTab,
        MuiTable,
        MuiTableCell: MuiTableCell(lightTheme),
        MuiTableHead,
        MuiTablePagination: MuiTablePagination(lightTheme),
        MuiTableRow,
        MuiCssBaseline: GlobalStyles(lightTheme),
        themeProps,
    },
});

export const mergedLightHighContrastTheme = createTheme(lightHighContrastTheme, {
    components: {
        MuiTab,
        MuiTable,
        MuiTableCell: MuiTableCell(lightHighContrastTheme),
        MuiTableHead,
        MuiTablePagination: MuiTablePagination(lightHighContrastTheme),
        MuiTableRow,
        MuiCssBaseline: GlobalStyles(lightHighContrastTheme),
        themeProps,
    },
});

export const mergedDarkHighContrastTheme = createTheme(darkHighContrastTheme, {
    components: {
        MuiTab,
        MuiTable,
        MuiTableCell: MuiTableCell(darkHighContrastTheme),
        MuiTableHead,
        MuiTablePagination: MuiTablePagination(darkHighContrastTheme),
        MuiTableRow,
        MuiCssBaseline: GlobalStyles(darkHighContrastTheme),
        themeProps,
    },
});

export const mergedDarkTheme = createTheme(darkTheme, {
    components: {
        MuiTab,
        MuiTable,
        MuiTableCell: MuiTableCell(darkTheme),
        MuiTableHead,
        MuiTablePagination: MuiTablePagination(darkTheme),
        MuiTableRow,
        MuiCssBaseline: GlobalStyles(darkTheme),
        themeProps,
    },
});

const UiThemeContext = React.createContext<{
    theme: Theme;
    themeId: ApolloThemeType;
    selectedThemeId: ThemeType;
    updateTheme: (theme: ApolloThemeType) => void;
    updateSelectedTheme: (theme: ThemeType) => void;
}>({
            selectedThemeId: defaultTheme,
            theme: mergedLightTheme,
            themeId: 'light',
            updateTheme: () => {},
            updateSelectedTheme: () => {},
        });

export const useApolloTheme = () => React.useContext(UiThemeContext);

const mergedThemes = new Map([
    [ 'light', mergedLightTheme ],
    [ 'dark', mergedDarkTheme ],
    [ 'light-hc', mergedLightHighContrastTheme ],
    [ 'dark-hc', mergedDarkHighContrastTheme ],
]);

export const ApolloThemeProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const autoThemeRef = useRef(false);
    const [ themeId, setThemeId ] = useState<ApolloThemeType>('light');
    const [ selectedThemeId, setSelectedThemeId ] = useState<ThemeType>(defaultTheme);

    const theme = useMemo(() => (mergedThemes.get(themeId) ?? mergedLightTheme), [ themeId ]);

    const updateTheme = useCallback((newThemeId: ApolloThemeType) => {
        autoThemeRef.current = false;
        setThemeId(newThemeId);
    }, []);

    const updateSelectedTheme = useCallback((newSelectedThemeId: ThemeType) => {
        setSelectedThemeId(newSelectedThemeId);
    }, []);

    useEffect(() => {
        const cachedSelectedTheme = getCachedTheme();
        setThemeId(getApolloThemeFromTheme(cachedSelectedTheme));
        setSelectedThemeId(cachedSelectedTheme);
    }, []);

    useEffect(() => {
        const themeChangedHandler = (event: any) => {
            const validThemeId = themeList.includes(event.detail.selectedThemeId);
            updateTheme(validThemeId ? event.detail.selectedThemeId : 'light');
        };

        const themeSelectChangedHandler = (event: any) => {
            updateSelectedTheme(event.detail.selectedThemeId);
        };

        document.addEventListener('themeChanged', themeChangedHandler);
        document.addEventListener('themeSelectChanged', themeSelectChangedHandler);

        return () => {
            document.removeEventListener('themeChanged', themeChangedHandler);
            document.removeEventListener('themeSelectChanged', themeSelectChangedHandler);
        };
    }, [ updateSelectedTheme, updateTheme ]);

    return (
        <StyledEngineProvider injectFirst>
            <UiThemeContext.Provider value={{
                theme,
                themeId,
                selectedThemeId,
                updateTheme,
                updateSelectedTheme,
            }}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {children}
                </ThemeProvider>
            </UiThemeContext.Provider>
        </StyledEngineProvider>
    );
};

export { GlobalStyles };

export * from './ThemeConstants';
