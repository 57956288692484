export async function parseFile(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = event => {
            const content = event.target?.result as string;
            resolve(content);
        };

        reader.onerror = () => {
            reader.abort();
            reject('file parse failed');
        };

        reader.readAsText(file);
    });
}

export async function imageUrlToBase64(blob: Blob | File): Promise<string> {
    return new Promise((onSuccess, onError) => {
        try {
            const reader = new FileReader() ;
            reader.onload = function() {
                onSuccess(this.result as any);
            } ;
            reader.readAsDataURL(blob) ;
        } catch (e) {
            onError(e);
        }
    });
}

export function isImage(urlOrFilename: string) {
    const imageExtensions = [ 'jpeg', 'jpg', 'gif', 'png', 'bmp', 'webp', 'svg' ];
    const extension = urlOrFilename.split('.').pop()
        ?.toLowerCase();

    return imageExtensions.includes(extension ?? '');
}
