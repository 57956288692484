import type { IUiLinkMap } from '@experiences/interfaces';

export const DataRegionLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/overview-guide/docs/data-residency-cloud' ],
    [ 'zh-CN', 'https://docs.uipath.com/overview-guide/lang-zh_CN/docs/data-residency-cloud' ],
    [ 'zh-TW', 'https://docs.uipath.com/overview-guide/lang-zh_CN/docs/data-residency-cloud' ],
    [ 'fr', 'https://docs.uipath.com/overview-guide/lang-fr/docs/data-residency-cloud' ],
    [ 'de', 'https://docs.uipath.com/overview-guide/lang-de/docs/data-residency-cloud' ],
    [ 'ja', 'https://docs.uipath.com/overview-guide/lang-ja/docs/data-residency-cloud' ],
    [ 'es', 'https://docs.uipath.com/overview-guide/lang-es/docs/data-residency-cloud' ],
    [ 'pt-BR', 'https://docs.uipath.com/overview-guide/lang-pt_BR/docs/data-residency-cloud' ],
]);

export const CanaryTenantLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/automation-cloud/docs/managing-tenants#using-a-canary-tenant' ],
    [ 'zh-CN', 'https://docs.uipath.com/automation-cloud/lang-zh_CN/docs/managing-tenants#using-a-canary-tenant' ],
    [ 'zh-TW', 'https://docs.uipath.com/automation-cloud/lang-zh_CN/docs/managing-tenants#using-a-canary-tenant' ],
    [ 'fr', 'https://docs.uipath.com/automation-cloud/lang-fr/docs/managing-tenants#using-a-canary-tenant' ],
    [ 'de', 'https://docs.uipath.com/automation-cloud/lang-de/docs/managing-tenants#using-a-canary-tenant' ],
    [ 'ja', 'https://docs.uipath.com/automation-cloud/lang-ja/docs/managing-tenants#using-a-canary-tenant' ],
    [ 'es', 'https://docs.uipath.com/automation-cloud/lang-es/docs/managing-tenants#using-a-canary-tenant' ],
    [ 'pt-BR', 'https://docs.uipath.com/automation-cloud/lang-pt_BR/docs/managing-tenants#using-a-canary-tenant' ],
]);

export const CanaryTenantLimitedServicesLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/automation-cloud/docs/managing-tenants#included-services' ],
    [ 'zh-CN', 'https://docs.uipath.com/automation-cloud/lang-zh_CN/docs/managing-tenants#included-services' ],
    [ 'zh-TW', 'https://docs.uipath.com/automation-cloud/lang-zh_CN/docs/managing-tenants#included-services' ],
    [ 'fr', 'https://docs.uipath.com/automation-cloud/lang-fr/docs/managing-tenants#included-services' ],
    [ 'de', 'https://docs.uipath.com/automation-cloud/lang-de/docs/managing-tenants#included-services' ],
    [ 'ja', 'https://docs.uipath.com/automation-cloud/lang-ja/docs/managing-tenants#included-services' ],
    [ 'es', 'https://docs.uipath.com/automation-cloud/lang-es/docs/managing-tenants#included-services' ],
    [ 'pt-BR', 'https://docs.uipath.com/automation-cloud/lang-pt_BR/docs/managing-tenants#included-services' ],
]);

export const SSORecommendedDeploymentLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/robot/docs/setting-up-interactive-sign-in#recommended-deployment' ],
    [ 'zh-CN', 'https://docs.uipath.com/robot/lang-zh_CN/docs/setting-up-interactive-sign-in#recommended-deployment' ],
    [ 'zh-TW', 'https://docs.uipath.com/robot/lang-zh_CN/docs/setting-up-interactive-sign-in#recommended-deployment' ],
    [ 'fr', 'https://docs.uipath.com/robot/lang-fr/docs/setting-up-interactive-sign-in#recommended-deployment' ],
    [ 'de', 'https://docs.uipath.com/robot/lang-de/docs/setting-up-interactive-sign-in#recommended-deployment' ],
    [ 'ja', 'https://docs.uipath.com/robot/lang-ja/docs/setting-up-interactive-sign-in#recommended-deployment' ],
    [ 'es', 'https://docs.uipath.com/robot/lang-es/docs/setting-up-interactive-sign-in#recommended-deployment' ],
    [ 'pt-BR', 'https://docs.uipath.com/robot/lang-pt_BR/docs/setting-up-interactive-sign-in#recommended-deployment' ],
]);

export const ConsumablesRobotUnitsLearnMoreLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/overview/other/latest/overview/service-licensing#robot-units' ],
    [ 'ja', 'https://docs.uipath.com/ja/overview/other/latest/overview/service-licensing#robot-units' ],
    [ 'de', 'https://docs.uipath.com/de/overview/other/latest/overview/service-licensing#robot-units' ],
    [ 'fr', 'https://docs.uipath.com/fr/overview/other/latest/overview/service-licensing#robot-units' ],
    [ 'es', 'https://docs.uipath.com/es/overview/other/latest/overview/service-licensing#robot-units' ],
    [ 'pt-BR', 'https://docs.uipath.com/pt-BR/overview/other/latest/overview/service-licensing#robot-units' ],
    [ 'zh-CN', 'https://docs.uipath.com/zh-CN/overview/other/latest/overview/service-licensing#robot-units' ],
]);

export const ConsumablesAiUnitsLearnMoreLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/ai-center/automation-cloud/latest/user-guide/ai-units' ],
    [ 'ja', 'https://docs.uipath.com/ja/ai-center/automation-cloud/latest/user-guide/ai-units' ],
    [ 'de', 'https://docs.uipath.com/de/ai-center/automation-cloud/latest/user-guide/ai-units' ],
    [ 'fr', 'https://docs.uipath.com/fr/ai-center/automation-cloud/latest/user-guide/ai-units' ],
    [ 'es', 'https://docs.uipath.com/es/ai-center/automation-cloud/latest/user-guide/ai-units' ],
    [ 'pt-BR', 'https://docs.uipath.com/pt-BR/ai-center/automation-cloud/latest/user-guide/ai-units' ],
    [ 'zh-CN', 'https://docs.uipath.com/zh-CN/ai-center/automation-cloud/latest/user-guide/ai-units' ],
]);

export const ConsumablesApiCallsLearnMoreLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/integration-service/automation-cloud/latest/user-guide/licensing-monitoring-api-usage' ],
    [ 'ja', 'https://docs.uipath.com/ja/integration-service/automation-cloud/latest/user-guide/licensing-monitoring-api-usage' ],
    [ 'de', 'https://docs.uipath.com/de/integration-service/automation-cloud/latest/user-guide/licensing-monitoring-api-usage' ],
    [ 'fr', 'https://docs.uipath.com/fr/integration-service/automation-cloud/latest/user-guide/licensing-monitoring-api-usage' ],
    [ 'es', 'https://docs.uipath.com/es/integration-service/automation-cloud/latest/user-guide/licensing-monitoring-api-usage' ],
    [ 'pt-BR', 'https://docs.uipath.com/pt-BR/integration-service/automation-cloud/latest/user-guide/licensing-monitoring-api-usage' ],
    [ 'zh-CN', 'https://docs.uipath.com/zh-CN/integration-service/automation-cloud/latest/user-guide/licensing-monitoring-api-usage' ],
]);

export const ConsumablesTransportUnitsLearnMoreLinks: IUiLinkMap = new Map(
    [ [ 'en', 'https://docs.uipath.com/test-suite/automation-cloud/latest/user-guide/change-impact-analysis' ] ]);

