import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';

/** Boolean Utils */
export function getBooleanValue(value: any) {
    if (isBoolean(value)) {
        return value;
    }
    return isString(value) ? value === 'true' : false;
}

/** String Utils */
export function isStringEmpty(input: string) {
    return input === undefined || input == null || input.trim() === '';
}

export function randomString(length: number, chars: string) {
    let result = '';
    for (let i = length; i > 0; --i) {
        result += chars[Math.round(Math.random() * (chars.length - 1))];
    }
    return result;
}

export function generateAlphaNumericRandomString(length: number) {
    return randomString(length, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
}

export function isHtml(str: any) {
    return /<[a-z][\s\S]*>/i.test(str);
}

export function hasOnlyAsciiChars(str: string) {
    return !!str && [ ...str ].some(char => char.charCodeAt(0) < 128);
}

export function isGuid(stringToTest: any) {
    const regexGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return regexGuid.test(stringToTest);
}

export const lowercaseContains = (withinValue: string | undefined, targetValue: string | undefined) => {
    if (!targetValue || !withinValue) {
        return false;
    }
    return withinValue.toLowerCase().indexOf(targetValue.toLowerCase()) >= 0;
};

export function getMiddleTruncatedString(input: string, lengthToTruncateAt = 36, lengthToInclude = 14) {
    // return input str if:
    //  input string doesn't exist
    //  the length of the string is shorter than the length to truncate at
    //  the 2 parts to include are longer than the length of the string
    if (!input || input.length < lengthToTruncateAt || input.length < lengthToInclude * 2) {
        return input;
    }

    return `${input.slice(0, lengthToInclude)}[...]${input.slice(input.length - lengthToInclude, input.length)}`;
}

/** Number Utils */
export function getShortNumber(number: number) {
    let unit = '';
    if (number >= 1e3 && number < 1e6) {
        number /= 1e3;
        unit = 'K';
    } else if (number >= 1e6 && number < 1e9) {
        number /= 1e6;
        unit = 'M';
    } else if (number >= 1e9 && number < 1e12) {
        number /= 1e9;
        unit = 'B';
    }

    return Number(number).toFixed(0) + unit;
}

/** List */
export function deserializeList(list: string) {
    return list && list.indexOf(':') > -1
        ? list
            .replace(/\s+/g, '') // remove whitespace
            .split(',') // split by ',' to get kv pairs
            .map(pair => {
                const split = pair.split(':');
                return {
                    key: split[0],
                    value: split[1],
                };
            })
        : undefined;
}
export function roundTo2Decimals(num: number) {
    return Math.round(num * 100) / 100;
}
