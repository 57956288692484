export const OperationTypeConstants = {
    TENANT: 'tenant',
    SERVICE: 'service',
    ORGANIZATION: 'organization',
};

export const CommandTableOperationConstants = {
    QUEUD: 'Queued',
    CREATING: 'Creating',
    CREATED: 'Created',
    UPDATING: 'Updating',
    UPDATED: 'Updated',
    DELETING: 'Deleting',
    DELETED: 'Deleted',
    ENABLING: 'Enabling',
    ENABLED: 'Enabled',
    DISABLING: 'Disabling',
    DISABLED: 'Disabled',
    FAILED: 'Failed',
};
