import { produce } from 'immer';
import { useCallback } from 'react';
import type {
    Path,
    To,
} from 'react-router-dom';
import { useParams } from 'react-router-dom';

export function useRouteResolver() {
    const { accountName } = useParams() as { accountName: string };

    return useCallback(
        <T extends To>(route: T, customAccountName?: string): T => {
            if (typeof route === 'string') {
                return route.replace(':accountName', customAccountName ?? accountName) as T;
            }

            return produce(route as Partial<Path>, draft => {
                draft.pathname = route.pathname?.replace(':accountName', customAccountName ?? accountName);
            }) as T;
        },
        [ accountName ],
    );
}
