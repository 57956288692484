import type { LanguageCulture } from '@experiences/locales';
import {
    en,
    IntlWrapperContext,
} from '@experiences/locales';
import type { PropsWithChildren } from 'react';
import React from 'react';
import {
    createIntl,
    RawIntlProvider,
} from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import type { Store } from 'redux';
import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { SWRConfig } from 'swr';

export const initialState = {
    profile: {
        userGlobalId: 'a-fake-user-id',
        accountRoleType: 'ACCOUNT_ADMIN',
        groups: [],
        emailId: 'test.user@uipath.com',
        accountUserDto: {
            id: 'a-fake-id',
            firstName: 'test',
            lastName: 'user',
            companyName: 'fakecompany',
            accountLogicalName: 'fakeaccount',
            accountType: 'COMMUNITY',
            accountGlobalId: 'a-fake-global-id',
            country: 'United States',
        },
    },
};

const rootReducer = combineReducers({ profile: () => ({}) });

export const createWrapper = (props: {
    customLanguage?: LanguageCulture;
    customStore?: Store;
}) => (({ children }: any) => <HookTestWrapper {...props}>
    {children}
</HookTestWrapper>) as React.FC;

export const HookTestWrapper = ({
    children,
    customLanguage = 'en',
    customStore = createStore(rootReducer, initialState, compose(applyMiddleware(thunk))),
}: PropsWithChildren<{
    customLanguage?: LanguageCulture;
    customStore?: Store;
}>) => (
    <Provider store={customStore}>
        <IntlWrapperContext.Provider value={{
            locale: customLanguage,
            setLocale: () => {},
            setRoute: () => {},
        }}>
            <RawIntlProvider value={createIntl({
                messages: en,
                locale: 'en',
            })}>
                <SWRConfig value={{ provider: () => new Map() }}>
                    <BrowserRouter>
                        {children}
                    </BrowserRouter>
                </SWRConfig>
            </RawIntlProvider>
        </IntlWrapperContext.Provider>
    </Provider>
);

export default HookTestWrapper;
