export enum NotificationSettingsEvent {
    LoadPreferences = 'NotificationSettings.loadPreferences',
    EmailConfigNavigate = 'NotificationSettings.EmailConfigNavigate',
    SlackConfigNavigate = 'NotificationSettings.SlackConfigNavigate',
    SetSlackConfig = 'NotificationSettings.SetSlackConfig',
    SetEmailConfig = 'NotificationSettings.SetEmailConfig',
    SubscriptionChanges = 'NotificationSettings.SubscriptionChanges',
    TeamsConfigNavigate = 'NotificationSettings.TeamsConfigNavigate',
    SetTeamsConfig = 'NotificationSettings.SetTeamsConfig',
}
