export enum UnifiedUserProfileEvent {
    GroupMembershipsClick = 'UnifiedUserProfile.GroupMembershipsClick',
    LicensesClick = 'UnifiedUserProfile.LicensesClick',
    AccessClick = 'UnifiedUserProfile.AccessClick',
    InfoClick = 'UnifiedUserProfile.InfoClick',
}

export enum UnifiedGroupProfileEvent {
    GroupMembersClick = 'UnifiedGroupProfile.GroupMembersClick',
    LicensesClick = 'UnifiedGroupProfile.LicensesClick',
    AccessClick = 'UnifiedGroupProfile.AccessClick',
    InfoClick = 'UnifiedGroupProfile.InfoClick',
}
