import type { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export default (theme: Theme) =>
    createStyles({
        styleOverrides: {
            root: {
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                color: theme.palette.semantic.colorForeground,
                padding: '12px 0px 12px 16px',
                borderBottom: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
                midWidth: 0,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
            head: {
                fontWeight: 600,
                padding: '16px',
                cursor: 'default',
                backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
                borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            },
        },
    });
