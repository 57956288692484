import type { IUiSnackbarHookParams } from '@experiences/interfaces';
import React from 'react';

export interface IUiSnackbarContext {
    enqueueSnackbar: (message: string, options: IUiSnackbarHookParams) => void;
}

export const UiSnackbarContext = React.createContext<IUiSnackbarContext>({ enqueueSnackbar: (_message, _options) => {} });

export const useSnackbar = () => React.useContext(UiSnackbarContext);

