import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import { processLocaleKeys } from './LocaleAvailability';
import { useLocalization } from './LocaleProvider';
import en from './translation-countries-en.json';

const countrySelector = (state: any) => state.profile?.accountUserDto?.country as string;

export const useTranslatedCountries = () => {
    const locale = useLocalization(false);
    // TODO: remove react-redux dependency - this is a library that will not know
    //       about the redux store up the chain.
    const accountCountry = useSelector(countrySelector);
    const EnableLocKeysLanguage = useFeatureFlagValue(Features.EnableLocKeysLanguage.name);

    const enCountries = useMemo(() => Object.values(en), []);
    const [ translatedCountries, setTranslatedCountries ] = useState<Record<string, string> | string[]>(enCountries);
    const [ countryCodeCountries, setCountryCodeCountries ] = useState<Record<string, string>>(en);

    useEffect(() => {
        (async () => {
            if (locale?.toLowerCase() === 'keys') {
                processLocaleKeys(EnableLocKeysLanguage, setTranslatedCountries, en);
                return;
            }

            const localeData = await import(`./translation-countries-${locale}.json`);

            setCountryCodeCountries(localeData);

            const values: string[] = Object.values(localeData);
            const entries = enCountries.reduce((accumulator, current, index) => {
                accumulator[current] = values[index];
                return accumulator;
            }, {} as Record<string, string>);
            setTranslatedCountries(entries);
        })();
    }, [ EnableLocKeysLanguage, enCountries, locale ]);

    const accountCountryCode = useMemo(
        () =>
            Object.entries(en)
                .find(entry => entry[1]?.toLowerCase() === accountCountry?.toLowerCase())
                ?.shift() ?? '',
        [ accountCountry ],
    );

    return {
        translatedCountries,
        countryCodeCountries,
        accountCountryCode,
        englishCountryCodeCountries: en,
    };
};

