import { GlobalStyles } from '@experiences/theme';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import { clsx } from 'clsx';
import FileSaver from 'file-saver';
import type {
    FC,
    KeyboardEvent,
} from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { UiText } from '../UiText/UiText';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        imageContainer: {
            boxSizing: 'border-box',
            borderRadius: '3px',
            width: '216px',
            minHeight: '32px',
            maxHeight: '34px',
        },
        dragDropContainer: {
            boxSizing: 'border-box',
            border: '1px dashed ' + theme.palette.semantic.colorBorderDeEmp,
            borderRadius: '3px',
            width: '216px',
            minHeight: '80px',
            maxHeight: '87px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
        },
        dragDropContainerError: { border: `1px solid ${theme.palette.semantic.colorErrorText} !important` },
        actionContainer: {
            fontWeight: `${Tokens.FontFamily.FontWeightDefault} !important`,
            fontSize: `${Tokens.FontFamily.FontSSize} !important`,
            left: '2px',
            textAlign: 'left',
            marginTop: '2px',
        },
        dropText: {
            fontWeight: `${Tokens.FontFamily.FontWeightDefault} !important`,
            fontSize: `${Tokens.FontFamily.FontSSize} !important`,
            left: '22px',
            textAlign: 'center',
            marginTop: '17px',
        },
        dropTextSub: {
            fontWeight: `${Tokens.FontFamily.FontWeightDefault} !important`,
            fontSize: `${Tokens.FontFamily.FontXsSize} !important`,
            textAlign: 'center',
            color: theme.palette.semantic.colorForegroundDisable,
        },
        thumbsContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        thumb: {
            display: 'inline-flex',
            borderRadius: 3,
            marginRight: Tokens.Padding.PadL,
            width: 100,
            height: 'auto',
            maxHeight: 85,
            padding: 4,
            boxSizing: 'border-box',
        },
        thumbInner: {
            display: 'flex',
            minWidth: 0,
            overflow: 'hidden',
        },
        img: {
            display: 'block',
            width: '100%',
            height: '100%',
        },
        link: {
            alignSelf: 'flex-start',
            fontSize: Tokens.FontFamily.FontSSize,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
            lineHeight: '20px',
            cursor: 'pointer',
            marginRight: '2px',
        },
        errorMessage: {
            fontSize: Tokens.FontFamily.FontSSize,
            color: theme.palette.semantic.colorErrorText,

        },
        imageIcon: {
            width: Tokens.FontFamily.FontLSize,
            height: Tokens.FontFamily.FontLSize,
        },
    }),
}));

interface IImageObject {
    file: File;
    preview: string;
}
export const UiImageSelector: FC<{
    handleFileChange: (result: string | ArrayBuffer | null) => void;
    maxSize: number;
    preview: boolean;
    allowedTypes: string[];
    defaultImage?: string;
    defaultImageName?: string;
}> = ({
    handleFileChange, maxSize, preview, allowedTypes, defaultImage, defaultImageName,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const imageDropArea = useRef<HTMLDivElement>(null);
    const inputHtml = useRef<HTMLInputElement>(null);
    const [ file, setFile ] = useState<IImageObject>();
    const [ errorMessage, setErrorMessage ] = useState<string>('');
    const imageSrc = file?.preview ?? defaultImage;
    const showPreview = useMemo(() => file ?? defaultImage, [ file, defaultImage ]);

    const dispatchFileChange = useCallback((droppedFile: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(droppedFile);
        reader.onload = function() {
            handleFileChange(reader.result);
        };
    }, [ handleFileChange ]);

    const handleFile = useCallback((imageObject: IImageObject) => {
        const fileType = imageObject.file.type;
        if (imageObject.file.size > maxSize) {
            setErrorMessage(translate({ id: 'CLIENT_NOTIFICATION_IMAGECONFIG_SIZE_NSUPPORTED' },
                { 0: (Math.round(maxSize / 1024)).toFixed(0) }));
        } else if (allowedTypes.includes(fileType) || allowedTypes.includes('image/*')) {
            setErrorMessage('');
            setFile(imageObject);
            dispatchFileChange(imageObject.file);
        } else {
            setErrorMessage(translate({ id: 'CLIENT_NOTIFICATION_IMAGECONFIG_TYPE_NSUPPORTED' }));
        }
    }, [ allowedTypes, dispatchFileChange, maxSize, translate ]);

    const dropSelectHandler = useCallback((event: any) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer?.files[0] ?? event.target.files[0];
        const image: IImageObject = {
            file: droppedFile,
            preview: URL.createObjectURL(droppedFile),
        };
        handleFile(image);
    }, [ handleFile ]);

    const clearFileHandler = useCallback(() => {
        setErrorMessage('');
        setFile(undefined);
        handleFileChange('');
    }, [ handleFileChange ]);

    const downloadFileHandler = useCallback(() => {
        if (file !== undefined) {
            FileSaver.saveAs(file.file, file.file.name);
        } else if (defaultImage) {
            FileSaver.saveAs(defaultImage, defaultImageName);
        }
    }, [ file, defaultImage, defaultImageName ]);

    const onDragOver = useCallback((event: Event) => {
        event.stopPropagation();
        event.preventDefault();
    }, []);

    useEffect(() => {
        const { current: dropOutArea } = imageDropArea;
        const { current: imageSelectorInput } = inputHtml;
        dropOutArea?.removeEventListener('drop', dropSelectHandler);
        dropOutArea?.removeEventListener('dragover', dropSelectHandler);
        imageSelectorInput?.removeEventListener('change', dropSelectHandler);

        dropOutArea?.addEventListener('drop', dropSelectHandler);
        dropOutArea?.addEventListener('dragover', onDragOver);
        imageSelectorInput?.addEventListener('change', dropSelectHandler);

        return () => {
            dropOutArea?.removeEventListener('drop', dropSelectHandler);
            dropOutArea?.removeEventListener('dragover', dropSelectHandler);
            imageSelectorInput?.removeEventListener('change', dropSelectHandler);
        };
    }, [ dropSelectHandler, onDragOver ]);

    useEffect(() => () => {
        URL.revokeObjectURL(file?.preview ?? '');
    }, [ file ]);

    const renderLink = useCallback((chunk: React.ReactNode[]) => (
        <Link
            className={classes.a}
            underline='none'
            onClick={() => {
                inputHtml.current?.click();
            }}
            target="_blank"
            rel="noopener noreferrer">
            {chunk}
        </Link>
    ), [ classes.a ]);

    return (
        <div>
            <div
                ref={imageDropArea}
                data-testid="image-drop-area"
                className={clsx(classes.dragDropContainer,
                    errorMessage ? classes.dragDropContainerError : '')}
            >
                { preview && showPreview && <aside className={classes.thumbsContainer}>
                    <div className={classes.thumb}>
                        <div className={classes.thumbInner}>
                            <img
                                alt={file?.file.name}
                                src={imageSrc}
                                className={classes.img}
                                onLoad={() => {
                                    if (file?.preview) {
                                        URL.revokeObjectURL(file?.preview);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </aside>}
                {!showPreview && <>
                    <div className={classes.dropText}>
                        <UiText className={classes.link}>
                            <FormattedMessage
                                id="CLIENT_NOTIFICATION_IMAGECONFIG_DRAGDROP"
                                values={{ a: renderLink }}
                            />
                        </UiText>
                    </div>
                    <div className={classes.dropTextSub} >
                        {translate({ id: 'CLIENT_NOTIFICATION_IMAGECONFIG_SUPPORTEDMSG' }, { 0: allowedTypes })}
                    </div>
                </>}

                <input
                    ref={inputHtml}
                    type="file"
                    accept={allowedTypes.toString()}
                    hidden />
            </div>
            <div className={classes.imageContainer}>
                <Grid
                    container
                    spacing={1}>
                    {errorMessage && <Grid
                        item
                        xs={12}
                        md={12}
                    >
                        <UiText className={classes.errorMessage}>
                            {errorMessage}
                        </UiText>
                    </Grid>}

                    <Grid
                        item
                        xs={6}
                        md={6}
                    >
                        {showPreview && <Link
                            tabIndex={0}
                            align="left"
                            underline='none'
                            onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
                                if (e.key === 'Enter') {
                                    inputHtml.current?.click();
                                }
                            }}
                            className={classes.link}
                            onClick={() => {
                                inputHtml.current?.click();
                            }}
                            target="_blank"
                            rel="noopener noreferrer">
                            {translate({ id: 'CLIENT_REPLACEIMAGE' })}
                        </Link>}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={6}
                        style={{ textAlign: 'right' }}>
                        {(showPreview) && <IconButton onClick={downloadFileHandler} >
                            <DownloadIcon className={classes.imageIcon} />
                        </IconButton>}
                        {(showPreview) && <IconButton onClick={clearFileHandler} >
                            <DeleteOutlineIcon className={classes.imageIcon} />
                        </IconButton>}
                    </Grid>
                </Grid>

            </div>
        </div>
    );
};
