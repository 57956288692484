export enum SecuritySettingsEvent {
    AuthSettingsEnterprise = 'SecuritySettings.AuthSettingsClickedEnterpriseChip',
    AuthSettingsEnterpriseUpgrade = 'SecuritySettings.AuthSettingsClickedEnterpriseChipUpgrade',
    AuthSettingsEnterpriseContact = 'SecuritySettings.AuthSettingsClickedEnterpriseChipContact',
    LearnMoreEnterprise = 'SecuritySettings.AuthSettingsLearnMoreClickedEnterpriseChip',
    LearnMoreEnterpriseUpgrade = 'SecuritySettings.AuthSettingsLearnMoreClickedEnterpriseChipUpgrade',
    LearnMoreEnterpriseContact = 'SecuritySettings.AuthSettingsLearnMoreClickedEnterpriseChipContact',
    SessionPolicy = 'SecuritySettings.ClickedSessionPolicy',
    SessionPolicyUpgrade = 'SecuritySettings.ClickedSessionPolicyUpgrade',
    SessionPolicyContact = 'SecuritySettings.ClickedSessionPolicyContact',
    SessionPolicyDocs = 'SecuritySettings.ClickedSessionPolicyDocs',
    EncryptionChip = 'SecuritySettings.EncryptionClickedEnterpriseChip',
    EncryptionChipUpgrade = 'SecuritySettings.EncryptionClickedEnterpriseChipUpgrade',
    EncryptionChipContact = 'SecuritySettings.EncryptionClickedEnterpriseChipContact',
    EncryptionChipDocs = 'SecuritySettings.EncryptionClickedEnterpriseChipDocs',
    IPRestriction = 'SecuritySettings.ClickedIPRestrictions',
    IPRestrictionUpgrade = 'SecuritySettings.ClickedIPRestrictionsUpgrade',
    IPRestrictionContact = 'SecuritySettings.ClickedIPRestrictionsContact',
    IPRestrictionDocs = 'SecuritySettings.ClickedIPRestrictionsDocs',
    AccessPolicy = 'SecuritySettings.ClickedRestrictAccess',
    AccessPolicyUpgrade = 'SecuritySettings.ClickedRestrictAccessUpgrade',
    AccessPolicyContact = 'SecuritySettings.ClickedRestrictAccessContact',
    AccessPolicyDocs = 'SecuritySettings.ClickedRestrictAccessDocs',
}
