import type { Store } from 'redux';
import {
    applyMiddleware,
    compose,
    createStore,
} from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducer';

const initialState: any = { profile: {} };

/// TODO: remove reference to redux in global app
/// currently required due to ecommerce components

let store: Store;
if (process.env.NODE_ENV === 'development') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
} else {
    store = createStore(rootReducer, initialState, applyMiddleware(thunk));
}

export default store;
