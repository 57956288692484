import type { ButtonTypeMap } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { ExtendButtonBase } from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
    wrapper: { position: 'relative' },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const UiProgressButton: ExtendButtonBase<ButtonTypeMap<
{ loading: boolean; variant?: string; component?: any; target?: any; innerButtonClass?: string }
>> = (props: any) => {
    const classesInt = useStyles();
    const {
        loading, disabled, className, innerButtonClass, fullWidth, ...rest
    } = props;

    return (
        <div
            className={clsx(classesInt.wrapper, className ?? '')}
            style={{ width: fullWidth ? '100%' : undefined }}>
            <Button
                {...rest}
                fullWidth={fullWidth}
                className={innerButtonClass}
                disabled={loading || disabled} />
            {props.loading && <CircularProgress
                size={24}
                className={classesInt.buttonProgress}
                data-testid="ui-progress-button-loading"
                data-cy="ui-progress-button-loading" />}
        </div>
    );
};
