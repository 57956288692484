import { duration } from '@mui/material/styles';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import type { To } from 'react-router-dom';

import { useNavigateWithParams } from './useNavigateWithParams';

// default material "closing" animation speed
export const ANIMATION_DELAY_MS = duration.leavingScreen;

export function useModalState(parentRoute?: To, goBack?: boolean) {
    const navigate = useNavigateWithParams();

    // default state is open because each modal is on a unique route
    const [ open, setOpen ] = useState(false);

    const close = useCallback(
        (refresh?: boolean, otherState?: any) => {
            setOpen(false);

            const state = {
                ...(refresh && { refresh: true }),
                ...otherState,
            };

            if (parentRoute) {
                setTimeout(() => {
                    navigate(parentRoute, { state });
                }, ANIMATION_DELAY_MS);
            }

            if (goBack) {
                setTimeout(() => {
                    navigate(-1);
                }, ANIMATION_DELAY_MS);
            }
        },
        [ goBack, navigate, parentRoute ],
    );

    useEffect(() => {
        setOpen(true);
    }, []);

    return {
        open,
        close,
    };
}
