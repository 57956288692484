export enum OrgConsentEvent {
    CardClick = 'OrgConsent.CardClick',
    CreateConsent = 'OrgConsent.Create',
    EditConsent = 'OrgConsent.Edit',
    DeleteConsent = 'OrgConsent.Delete',
    DeactivateConsent = 'OrgConsent.Deactivate',
    ActivateConsent = 'OrgConsent.Activate',
    ViewUserRecords = 'OrgConsent.ViewUserRecordsClick',
    Reconsent = 'OrgConsent.Reconsent',
    Save = 'OrgConsent.Save',
    Export = 'OrgConsent.Export',
    Cancel = 'OrgConsent.Cancel',
}
