import { getUserLanguageFromLocalStorage } from '@experiences/locales';

export async function getHeaders(token: string, headers?: Record<string, string>) {
    const reqHeaders: Record<string, string> = {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'x-uipath-localization': getUserLanguageFromLocalStorage(),
    };

    if (headers?.extendRequestHeaders === 'true') {
        delete headers.extendRequestHeaders;
        headers = {
            ...reqHeaders,
            ...headers,
        };
    }

    return headers ?? reqHeaders;
}
