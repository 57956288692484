export enum SpacingToken {
    None = 'spacingNone',
    Micro = 'spacingMicro',
    XS = 'spacingXS',
    S = 'spacingS',
    Base = 'spacingBase',
    M = 'spacingM',
    L = 'spacingL',
    XL = 'spacingXL',
    XXL = 'spacingXXL',
}

const spacingMap: Record<SpacingToken, number> = {
    spacingNone: 0,
    spacingMicro: 4,
    spacingXS: 8,
    spacingS: 12,
    spacingBase: 16,
    spacingM: 20,
    spacingL: 24,
    spacingXL: 32,
    spacingXXL: 40,
};

export const alignMap = {
    start: 'flex-start',
    end: 'flex-end',
    'flex-start': 'flex-start',
    'flex-end': 'flex-end',
    center: 'center',
    stretch: 'stretch',
    baseline: 'baseline',
};

export const justifyMap = {
    start: 'flex-start',
    end: 'flex-end',
    'flex-start': 'flex-start',
    'flex-end': 'flex-end',
    center: 'center',
    between: 'space-between',
    around: 'space-around',
    evenly: 'space-evenly',
    'space-between': 'space-between',
    'space-around': 'space-around',
    'space-evenly': 'space-evenly',
};

export function calcSpacingPx(value?: SpacingToken | number | string) {
    if (value === undefined) {
        return undefined;
    } else if (value in spacingMap) {
        return spacingMap[value as SpacingToken];
    } else if (typeof value === 'number' || typeof value === 'string') {
        return value;
    }
    return undefined;
}
