/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/styles';
import React from 'react';
import { useIntl } from 'react-intl';

export const UiLogo: React.FC<{
    height?: number;
    width?: number;
    color?: string;
    className?: string;
    'data-cy'?: string;
}> = ({
    height = 42, width = 114, color, className, 'data-cy': dataCy,
}) => {
    const theme = useTheme();
    const { formatMessage: translate } = useIntl();

    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox="0 0 307.05338 110"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{
                width,
                height,
            }}
            className={className}
            data-cy={dataCy}
            data-testid="ui-logo"
            aria-hidden={false}
            aria-label={translate({ id: 'CLIENT_UIPATH_IMAGE_LOGO' })}
            titleAccess={translate({ id: 'CLIENT_UIPATH_IMAGE_LOGO' })}
            role='img'
        >
            <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                aria-label={translate({ id: 'CLIENT_UIPATH_IMAGE_LOGO' })}
                role='img'>
                <g
                    fill={color ?? theme.palette.semantic.colorLogo}
                    fillRule="nonzero"
                >
                    <path d="M0,0h109.99997v109.99997H0V0z M11.38617,98.6138H98.6147V11.38572H11.38617V98.6138z M55.36715,28.37218h11.38617v33.33049c0,15.05782-8.53941,24.14865-22.95526,24.14865c-14.14058,0-22.49618-8.90702-22.49618-24.14865V28.37218h11.38617v33.33049c0,8.3556,3.48875,13.40536,11.38528,13.40536c7.62127,0,11.29382-4.7745,11.29382-13.40536V28.37218z M88.42193,28.37218c0,4.04017-2.93822,6.79458-6.97749,6.79458c-4.04017,0-6.97839-2.75442-6.97839-6.79458c0-4.13162,2.93822-6.97794,6.97839-6.97794C85.4837,21.39424,88.42193,24.24056,88.42193,28.37218z M75.7509,40.58414h11.38617V85.2999H75.7509V40.58414z M163.53085,47.47062c0,12.21196-8.08034,19.46561-20.4761,19.46561h-10.28333V85.2999h-11.38618V28.37218h21.6695C155.63432,28.37218,163.53085,35.71774,163.53085,47.47062z M151.96176,47.47062c0-6.24361-3.5811-9.91661-10.10042-9.91661h-9.08992v20.29229h9.08992C148.38066,57.84631,151.96176,54.17375,151.96176,47.47062z M201.8192,40.58414h11.38617V85.2999H201.8192v-5.04976c-3.02968,3.5811-7.80418,5.60118-14.50731,5.60118c-12.1205,0-20.65901-9.5499-20.65901-22.8629c0-13.222,8.35559-22.95525,20.65901-22.95525c6.51932,0,11.47763,2.2958,14.50731,6.15215V40.58414z M201.8192,62.98842c0-7.71317-4.5907-13.03864-11.84435-13.03864c-7.25366,0-11.7529,5.05021-11.7529,13.03864c0,7.43656,4.04018,12.85394,11.7529,12.85394C196.95323,75.84235,201.8192,70.79259,201.8192,62.98842z M241.02657,75.56709h4.49924v9.7328h-5.9688c-10.83475,0-15.51779-5.14121-15.51779-15.7007V50.22549h-5.32503v-9.64135h5.32503V28.37218h11.38617v12.21196h10.00807v9.64135h-10.00807v19.46516C235.42538,73.63936,236.61879,75.56709,241.02657,75.56709z M294.6489,59.31497V85.2999h-11.38528V60.60072c0-6.79458-3.48962-11.20192-10.19275-11.20192c-6.70224,0-10.7424,4.59114-10.7424,12.21151V85.2999h-11.38528V25.61777h11.38528v19.55751c2.84586-3.39729,7.34509-5.23357,13.49771-5.23357C286.38477,39.94171,294.6489,47.56253,294.6489,59.31497z" />
                    <path d="M299.69287,37.17314c-4.39639,0-7.40411-3.06586-7.40411-7.3411c0-4.41581,3.1095-7.2999,7.40411-7.2999c4.35522,0,7.3605,2.94468,7.3605,7.2999C307.05338,34.16787,304.02872,37.17314,299.69287,37.17314z M299.69287,23.84089c-3.68146,0-6.01294,2.31212-6.01294,5.99115c0,3.68146,2.45511,5.95238,6.01294,5.95238c3.70087,0,5.99118-2.39452,5.99118-5.95238C305.68405,26.17239,303.35254,23.84089,299.69287,23.84089z M301.37003,30.9784l1.55353,2.90348h-1.84195l-1.47113-2.6587h-1.16574v2.6587h-1.65533v-8.26207h3.16766c1.80075,0,3.12888,1.06396,3.12888,2.76049C303.08594,29.64784,302.41217,30.54943,301.37003,30.9784z M298.44473,29.89505h1.30875c0.96216,0,1.61655-0.53319,1.61655-1.51475c0-0.89916-0.65439-1.42993-1.61655-1.42993h-1.30875V29.89505z" />
                </g>
            </g>
        </SvgIcon>
    );
};
