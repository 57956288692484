const IsProductionBuild = process.env.NODE_ENV === 'production';

const productionPort = (typeof window !== 'undefined' && (window as any).env?.PORT) ?? 8080;

const GlobalAppPort = IsProductionBuild ? productionPort : 3001;
const PortalPort = IsProductionBuild ? productionPort : 3000;

const localhost = 'http://localhost';

export const LocalGlobalAppOrigin = `${localhost}:${GlobalAppPort}`;
export const LocalPortalOrigin = `${localhost}:${PortalPort}`;

