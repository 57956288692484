import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import type { TreeItemProps } from '@mui/lab/TreeItem';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import {
    filterTreeByValue,
    hasFolderAsExpectedValue,
    resetTree,
} from './TreeComponentHelper';
import type { IFolderTreeDTO } from './TreeComponentInterfaces';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 500,
            width: 400,
            overflow: 'hidden',
        },
    },
};

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelInfo?: string;
    labelText: string;
    checked?: boolean;
    indeterminate?: boolean;
    updating?: boolean;
    keyid: string;
    handleChange?: (event: any) => void;
};
const StyledTreeItemRoot = styled(TreeItem)(() => ({}));

const StyledTreeItem = (props: StyledTreeItemProps) => {
    const {
        bgColor,
        color,
        labelInfo,
        checked,
        indeterminate,
        updating,
        keyid,
        labelText,
        handleChange,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <>
                    {updating &&
                    <CircularProgress
                        style={{
                            float: 'left',
                            margin: '13px',
                        }}
                        size={16}
                        thickness={4} />}
                    { !updating &&
                    <Checkbox
                        data-testid="multiselect-checkbox"
                        style={{ float: 'left' }}
                        checked={checked}
                        indeterminate={indeterminate}
                        id={keyid}
                        onChange={handleChange} />}
                    <ListItemText
                        primary={labelText}
                        style={{ paddingTop: '11px' }} />
                </>
            }
            {...other}
        />
    );
};
export const UiMultiSelectTree: React.FC<{
    treeNodes: IFolderTreeDTO[] | undefined;
    onChange?: (event: any) => void;
    onChangeAll?: (event: any) => void;
}> = ({
    treeNodes, onChange, onChangeAll,
}) => {
    const { formatMessage: translate } = useIntl();
    const [ searchValue, setSearchValue ] = React.useState<string>();
    const [ SelectAllChecked, SelectAnyChecked ] = useMemo(() => {
        const hasAnyCheck = hasFolderAsExpectedValue(treeNodes?.filter(x => !x.isCategorized) ?? [], true);
        const hasAnyUnchecked = hasFolderAsExpectedValue(treeNodes?.filter(x => !x.isCategorized) ?? [], false);
        return [ hasAnyCheck && !hasAnyUnchecked, hasAnyCheck && hasAnyUnchecked ];
    }, [ treeNodes ]);

    const handleChange = useCallback(
        async (event: any) => {
            onChange?.(event);
        }, [ onChange ]);

    const isParentIndeterminateState = useCallback((childs: IFolderTreeDTO[]) => hasFolderAsExpectedValue(childs, true), []);

    const filterTree = useCallback((filterValue: string, tree?: IFolderTreeDTO[]) => {
        filterTreeByValue(filterValue, tree);
    }, []);

    const resetFilterTree = useCallback((tree: IFolderTreeDTO[]) => {
        resetTree(tree);
    }, []);

    const handleTextChange = (event: any) => {
        resetFilterTree(treeNodes ?? []);
        filterTree(event.target.value, treeNodes);
        setSearchValue(event.target.value);
    };

    const renderTreeItems = useCallback((folders?: IFolderTreeDTO[]) => folders?.filter(x => !x.isCategorized).
        map((treeNode) => (!treeNode.isHidden &&
      <StyledTreeItem
          key={treeNode.Key}
          nodeId={treeNode.Key}
          keyid={treeNode.Key}
          updating={treeNode.isUpdating}
          checked={treeNode.isChecked}
          indeterminate={treeNode.isChecked || treeNode.children.length === 0 ? false : isParentIndeterminateState(treeNode.children)}
          labelText={treeNode.DisplayName}
          handleChange={handleChange}>
          { treeNode.children.length > 0 &&
              renderTreeItems(treeNode.children)}
      </StyledTreeItem>)), [ handleChange, isParentIndeterminateState ]);

    const renderCategorizedTreeItems = useCallback((folders?: IFolderTreeDTO[]) => folders?.
        filter(x => x.isCategorized).map((treeNode) => (!treeNode.isHidden &&
       <>
           <StyledTreeItem
               key={treeNode.Key}
               nodeId={treeNode.Key}
               keyid={treeNode.Key}
               updating={treeNode.isUpdating}
               checked={treeNode.isChecked}
               labelText={treeNode.DisplayName}
               handleChange={handleChange}>
               { treeNode.children.length > 0 &&
                renderTreeItems(treeNode.children)}
           </StyledTreeItem>
           <Divider />
       </>)), [ handleChange, renderTreeItems ]);

    const handleNodeSelect = (event: any) => {
        if (event.target.localName === 'path') {
            return true;
        }
        event.stopPropagation();
        if (event.onClick) {
            event.onClick(event);
        }
        return false;
    };

    return (
        <div>
            <FormControl sx={{ width: 400 }} >
                <InputLabel id="multiselect-checkbox-tree" />
                <Select
                    labelId="multiselect-checkbox-tree"
                    data-testid="multiselect-checkbox-tree-select"
                    id="multiselect-checkbox-tree-select"
                    multiple
                    value={[]}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                >
                    <Paper
                        elevation={0}
                        style={{ width: '98%' }}
                        component="form"
                        sx={{
                            p: '2px 4px',
                            display: 'flex',
                            alignItems: 'center',
                        }} >
                        <IconButton
                            type="button"
                            sx={{ p: '10px' }}
                            aria-label="search">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            onChange={(e) => {
                                handleTextChange(e);
                            }}
                            sx={{
                                ml: 1,
                                flex: 1,
                            }}
                            value={searchValue}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 's' }}
                        />
                    </Paper>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        onNodeSelect={handleNodeSelect}
                        sx={{
                            height: 440,
                            flexGrow: 1,
                            maxWidth: 500,
                            paddingTop: 1,
                            overflow: 'auto',
                        }}
                    >
                        <StyledTreeItem
                            nodeId='1'
                            keyid="1"
                            checked={SelectAllChecked}
                            indeterminate={SelectAnyChecked}
                            labelText={translate({ id: 'CLIENT_MULTISELECT_TREE_SELECTALL' })}
                            handleChange={onChangeAll} />
                        <Divider />
                        {renderCategorizedTreeItems(treeNodes)}
                        {renderTreeItems(treeNodes)}
                    </TreeView>
                </Select>
            </FormControl>
        </div>
    );
};
