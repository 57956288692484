export enum NavCustomizationSetting {
    AppsHidden = 'NavCustomization.AppsHidden',
    ResourceCenterHidden = 'NavCustomization.ResourceCenterHidden',
    MarketplaceHidden = 'NavCustomization.MarketplaceHidden',
    StudioWebHidden = 'NavCustomization.StudioWebHidden',
    IntegrationServiceHidden = 'NavCustomization.IntegrationServiceHidden',
    InsightsHidden = 'NavCustomization.InsightsHidden',
    AcademyHidden = 'NavCustomization.AcademyHidden',
    CustomerPortalHidden = 'NavCustomization.CustomerPortalHidden',
    AiCenterHidden = 'NavCustomization.AiCenterHidden',
    AutomationHubHidden = 'NavCustomization.AutomationHubHidden',
    CommunicationsMiningHidden = 'NavCustomization.CommunicationsMiningHidden',
    ProcessMiningHidden = 'NavCustomization.ProcessMiningHidden',
    SubmitIdeaHidden = 'NavCustomization.SubmitIdeaHidden',
}
