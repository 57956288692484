export const languageCodes = [
    'en',
    'ja',
    'de',
    'fr',
    'es',
    'pt-BR',
    'es-MX',
    'ko',
    'zh-CN',
    'zh-TW',
    'pt',
    'tr',
    'ru',
    'keys',
] as const;

export type LanguageCulture = typeof languageCodes[number];
